import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectListItems } from '../redux/inspectionchecklist/selectors.js';
import { Switch } from "@headlessui/react";
import { selectCurrentCRM } from "../redux/crm/selectors.js";
import { crm } from "../redux/crm/actions.js"
import { inspectionchecklist } from "../redux/inspectionchecklist/actions.js";
import {
	Accordion,
	AccordionHeader,
	AccordionBody,
} from "@material-tailwind/react";
import { projectchecklist } from "../redux/projectchecklist/actions.js";
function ProjectChecklist({
	device,
	setIsAddTaskModalOpen
}) {
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(1);
	const handleOpen = (value) => setOpen(open === value ? 0 : value);
	const { activeBoardName, checklistProjectType } = useSelector(selectCurrentCRM)
	const { result } = useSelector(selectListItems);
	const { items } = result
	const [pass, setPass] = useState([]);
	const [fail, setFail] = useState([]);
	const [notApplicable, setNotApplicable] = useState([]);
	const [checkList, setCheckList] = useState([]);
	const [categoryName, setCategoryName] = useState([]);

	const toggleStatus = (option, idx) => {
		if (option == 'pass') {
			setPass({ ...pass, [idx]: !pass[idx] })
			setFail({ ...fail, [idx]: pass[idx] })
			setNotApplicable({ ...notApplicable, [idx]: pass[idx] })
		}
		else if (option == 'fail') {
			setFail({ ...fail, [idx]: !fail[idx] })
			setPass({ ...pass, [idx]: fail[idx] })
			setNotApplicable({ ...notApplicable, [idx]: fail[idx] })
		}
		else if (option == 'notApplicable') {
			setNotApplicable({ ...notApplicable, [idx]: !notApplicable[idx] })
			setFail({ ...fail, [idx]: notApplicable[idx] })
			setPass({ ...pass, [idx]: notApplicable[idx] })
		}

	}

	const hanldeUpdate = () => {
		dispatch(projectchecklist.create({ entity: 'PDI_Project_Checklist', jsonData: { entity: { ProjectName: "65996e0da5c872001ab130df", Checklist: checkList } } }))
	}

	const handleCreateIsse = (item) => {
		dispatch(crm.trigger({ projectChecklistOpen: 'close', issueModalOpen: 'open', isIssueEdit: false, issueItem: item }))
	}

	useEffect(() => {
		dispatch(inspectionchecklist.list({ entity: 'PDI_Checklist_Items', options: { limit: 20 } }))
	}, [])

	useEffect(() => {
		let _categoryName = []
		let checkList = []
		let _pass = []
		let _fail = []
		let _notApplicable = []

		items?.map((item, index) => {
			_categoryName.push(item.categoryName.Name)
			_pass[index] = false
			_fail[index] = false
			_notApplicable[index] = false
		})
		let uniqueCategoryList = [...new Set(_categoryName)]
		uniqueCategoryList?.map((obj) => {
			checkList.push(items.filter((item) => item.categoryName.Name == obj && item.ProjectType.Name == checklistProjectType))
		})
		setCheckList(checkList)
		setPass(_pass)
		setFail(_fail)
		setNotApplicable(_notApplicable)
		setCategoryName(uniqueCategoryList)
	}, [items])

	return (
		<>
			<div
				className={
					device === "mobile"
						? "  py-6 px-6 pb-40  absolute  left-0 flex  right-0  top-0 dropdown "
						: "  py-6 px-6 pb-40  absolut   left-0 flex  right-0 bottom-0 top-0 dropdown "
				}
				onClick={(e) => {
					if (e.target !== e.currentTarget) {
						return;
					}
					setIsAddTaskModalOpen(false);
				}}
			>
				<div
					className="overflow-auto max-h-[90vh] my-auto  bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold
       shadow-md shadow-[#364e7e1a] max-w-7xl mx-auto  px-8  py-8 rounded-xl">
					<div className="flex flex-row justify-between items-center w-full border-b-2 py-4 mb-8">
						<h3 className=" text-lg  text-md  text-yellow-500">
							PDI Checklist
						</h3>
						<h3 className=" text-sm dark:bg-blue-600 text-md  text-white px-2 py-1 rounded-t-xl">
							{activeBoardName}
						</h3>
					</div>
					<div>
						{categoryName?.map((item, index) => {
							return <>
								<>
									<Accordion open={open === index + 1}>
										{checkList[index].length > 0 ? <AccordionHeader onClick={() => handleOpen(index + 1)} className="border-0">
											<div className="flex flex-row w-full justify-between items-center  px-10 py-2 dark:bg-blue-600" >
												<label className="text-sm dark:text-white text-gray-500 w-[600px]">
													{item}
												</label>
												<label className=" flex justify-center items-center text-sm dark:text-white  w-[100px]  text-gray-500">
													NA
												</label>
												<label className=" flex justify-center items-center text-sm dark:text-white  w-[100px]  text-gray-500">
													Pass
												</label>
												<label className=" flex justify-center items-center text-sm dark:text-white  w-[100px] text-gray-500">
													Fail
												</label>
												<label className=" flex justify-center items-center text-sm dark:text-white  w-[100px] text-gray-500">
													#
												</label>
											</div>
										</AccordionHeader> : ""}
										<AccordionBody className="my-0 py-0">
											{checkList[index].map((obj, idx) => {
												return <div className="flex flex-row w-full justify-around px-10  items-baseline bg-slate-200 texrt-slate-200 text-sm dark:bg-blue-900/5">
													<div className="mr-auto text-xs text-yellow-500 w-[600px]">{obj.description}</div>
													<div className=" flex  w-[100px] h-8 m-1 bg-slate-100 dark:bg-[#20212c] flex justify-center items-center rounded-lg">
														<div className=" ">
															<Switch
																checked={notApplicable[idx]}
																onChange={() => toggleStatus('notApplicable', idx)}
																className={`${notApplicable[idx] ? "bg-[#635fc7]" : "bg-gray-200"
																	} relative inline-flex h-3 w-8 items-center rounded-2xl`}
															>
																<span
																	className={`${notApplicable[idx] ? "translate-x-6" : "translate-x-1"
																		} inline-block h-4 w-4 transform rounded-2xl bg-white transition`}
																/>
															</Switch>
														</div>
													</div>
													<div className=" flex  w-[100px] h-8 m-1 bg-slate-100 dark:bg-[#20212c] flex justify-center items-center rounded-lg ">
														<Switch
															checked={pass[idx]}
															onChange={() => toggleStatus('pass', idx)}
															className={`${pass[idx] ? "bg-green-800" : "bg-gray-200"
																} relative inline-flex h-3 w-8 items-center rounded-2xl`}>
															<span
																className={`${pass[idx] ? "translate-x-6" : "translate-x-1"
																	} inline-block h-4 w-4 transform rounded-2xl bg-white transition`}
															/>
														</Switch>
													</div>
													<div className="flex w-[100px] h-8 m-1  bg-slate-100 dark:bg-[#20212c] flex justify-center items-center rounded-lg">
														<Switch
															checked={fail[idx]}
															onChange={() => toggleStatus('fail', idx)}
															className={`${fail[idx] ? "bg-red-800" : "bg-gray-200"
																} relative inline-flex h-3 w-8 items-center rounded-2xl`}>
															<span
																className={`${fail[idx] ? "translate-x-6" : "translate-x-1"
																	} inline-block h-4 w-4 transform rounded-2xl bg-white transition`}
															/>
														</Switch>
													</div>
													<div className="flex w-[100px] h-8 items-center text-xs underline  cursor-pointer  bg-slate-100 text-yellow-100 dark:bg-transparent flex justify-center items-center rounded-lg">
														{fail[idx] ? <span onClick={() => handleCreateIsse(obj)}>Create Issue</span> : '-'}
													</div>
												</div>
											})}
										</AccordionBody>
									</Accordion>
								</>
							</>
						})}
						< div className="w-full flex justify-end border-t-2 mt-5 border-white">
							<button
								onClick={() => dispatch(crm.trigger({ projectChecklistOpen: 'close' }))}
								className="text-white bg-[#635fc7] dark:bg-blue-600 text-xs px-4 py-2 m-3 rounded-xl" >
								Close
							</button>
							<button
								onClick={() => hanldeUpdate()}
								className="text-white bg-[#635fc7] dark:bg-blue-600 text-xs px-4 py-2 m-3 rounded-xl" >
								Update
							</button>
						</div>
					</div>
				</div>
			</div >
		</>
	);
}

export default ProjectChecklist;
