import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PageLoader from './../components/PageLoader';
import UserManagement from "../pages/auth/UserManagement.js";
import IssueManagement from "../pages/inspection-crm/IssueManagement.js";
import InspectionBoard from "../pages/inspection-crm/InspectionBoard.js";
import ProjectBoard from "../pages/inspection-crm/ProjectBoard.js";
import InspectionItems from '../pages/inspection-crm/InspectionItems';
import IssueItems from '../pages/inspection-crm/IssueItems';
import InspectionChecklist from '../pages/inspection-crm/InspectionChecklist';
import DocumentS3 from '../pages/inspection-crm/DocumentS3';
import ProjectChecklist from '../pages/inspection-crm/ProjectChecklist';
import Permissions from '../pages/auth/Permissions';
import MasterSettings from '../pages/inspection-crm/MasterSettings';
import Settings from '../pages/inspection-crm/Settings';
import { UserProvider } from '../contextHook/UserContext';
const Logout = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ './../pages/Logout'));
const NotFound = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ './../pages/NotFound'));
const Auth = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ './../layouts/Auth'));
const InspectionCRM = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ './../layouts/InspectionCRM'));
const Login = lazy(() => import(/*webpackChunkName:'LoginPage'*/ './../pages/auth/Login'));



export default function AppRouter() {
	return (
		<Suspense fallback={<PageLoader />}>
			<AnimatePresence exitBeforeEnter initial={false}>
				<UserProvider>
					<Routes>
						<Route element={<InspectionCRM />} >
							<Route path="/inspection-crm/project-boards" element={<ProjectBoard />} />
							<Route path="/inspection-crm/document-s3" element={<DocumentS3 />} />
							<Route path="/inspection-crm/inspection-boards" element={<InspectionBoard />} />
							<Route path="/inspection-crm/issues-management" element={<IssueManagement />} />
							<Route path="/inspection-crm/inspection-items" element={<InspectionItems />} />
							<Route path="/inspection-crm/project-checklist" element={<ProjectChecklist />} />
							<Route path="/inspection-crm/inspection-checklist-items" element={<InspectionChecklist />} />
							<Route path="/inspection-crm/issue-items" element={<IssueItems />} />
							<Route path="/inspection-crm/user-management" element={<UserManagement />} />
							<Route path="/inspection-crm/permissions" element={<Permissions />} />
							<Route path="/inspection-crm/settings" element={<Settings />} />
							<Route path="/inspection-crm/master-settings" element={<MasterSettings />} />


						</Route>
						<Route element={<Auth />} >
							<Route path="/" element={<Navigate to="/auth/login" />} />
							<Route path="/auth/login" element={<Login />} />

							<Route path="*" element={<Navigate to="/notfound" />} />
						</Route>
						<Route path="/" element={<Navigate to="/auth/login" replace={true} />} />
						<Route path="*" element={<Navigate to="/notfound" replace={true} />} />
						<Route path="/logout" element={Logout} />
						<Route path="/notfound" element={NotFound} />
					</Routes>
				</UserProvider>
			</AnimatePresence>
		</ Suspense >
	);
}
