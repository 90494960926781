import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { issuecards } from "../../redux/issuecards/actions";
import { crm } from "../../redux/crm/actions";
import { selectCurrentCRM } from "../../redux/crm/selectors"
import AddEditIssueItem from "../../modals/AddEditIssueItem"
import { selectListItems } from '../../redux/issuecards/selectors';
import { useNavigate } from 'react-router-dom';
import Confirmation from "./../../modals/Confirmation.js";
import Pagination from "./../../components/Pagination.js";
import TableDropdown from "../../modals/TableDropdown.js";
import _ from 'lodash'

export default function IssueItems() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const { activeBoardName, issueModalOpen, issueReload } = useSelector(selectCurrentCRM)
	const { result } = useSelector(selectListItems);
	const { items, pagination } = result
	const { current, pageSize } = pagination;
	const pageCount = pageSize;
	const columnName =
	{
		"IssuePriority": {},
		"Attachment": {},
		"IssueTitle": {},
		"Description": {},
		"IssueStatus": {},
		"InspectionCard": {},
		"CreatedDate": {},
	}

	// useEffect(() => {
	// 	setNewItems(items)
	// }, [items])

	const displayFormat = (data) => {
		return _.startCase(data)
	}

	const propertyNames = Object.keys(columnName);

	const handleUpload = () => {

	}

	const hanldeAddIssue = () => {
		dispatch(crm.trigger({ issueModalOpen: 'open', isIssueEdit: false }))
	}

	useEffect(() => {
		dispatch(issuecards.list({ entity: 'PDI_Issue_Management', options: { page: 1, limit: 5 } }))
	}, [])

	useEffect(() => {
		if (issueReload) {
			dispatch(issuecards.list({ entity: 'PDI_Issue_Management', options: { page: 1, limit: 5 } }))
			dispatch(crm.trigger({ issueReload: false }))
		}
	}, [issueReload])

	return (
		<>
			<div className="flex flex-col item-center">
				<header className="flex justify-between h-20 w-full mx-auto px-5 dark:text-white items-center">
					<div className="flex justify-start w-full" onClick={() => {
						navigate('/inspection-crm/inspection-items')
					}}   >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#" className="opacity-60">
										<svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20"
											fill="currentColor">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z">
											</path>
										</svg>
									</a>
									<span
										className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
										/
									</span>
								</li>
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#" className="opacity-60">
										<span>
											{activeBoardName}
										</span>
									</a>
									<span
										className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">/</span>
								</li>
								<li onClick={() => navigate('/inspection-crm/inspection-items')}
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<span className="opacity-60">
										Inspection Id - 234
									</span>
									<span
										className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">/</span>
								</li>
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#">
										Issue Items
									</a>
								</li>
							</ol>
						</nav>
					</div>
					<div className="flex justify-end w-full " >
						<button className="w-36 w-min-36 button  border-r-2 border-black uppercase hidden md:block rounded-r-3xl dark:bg-blue-800 p-2 py-3 text-xs font-bold"
							onClick={() => {
								navigate('/inspection-crm/issue-items')
							}} > Table View
						</button>
						<button className="w-36 w-min-36 button uppercase hidden md:block rounded-l-3xl dark:bg-blue-600 p-2 text-xs font-bold"
							onClick={() => {
								navigate('/inspection-crm/issues-management')
							}} > Kanban Board
						</button>
					</div>
				</header >
				<>
					<Confirmation />
					<div className="flex flex-col  px-3 mx-5 h-[600px] shadow-lg rounded bg-white dark:bg-[#2b2c37]">
						<div className="rounded-t mb-0 px-4 py-3 border-0 ">
							<div className="flex flex-row items-center ">
								<div className=" w-screen px-2">
									<h3
										className="font-semibold text-lg dark:text-white" >
										PDI_Issue_Items
									</h3>
								</div>
								<div className="flex justify-end w-screen space-x-3" >
									<button onClick={() => hanldeAddIssue()} className="bg-blue-600 mb-2 text-black active:bg-blue-600 font-bold uppercase text-xs 
							 text-white py-2 px-4 rounded shadow hover:shadow-md outline-none focus:outline-none
							 ease-linear transition-all duration-150">Add_Edit_PDI_Issue_Item</button>
									<button className="bg-blue-600 mb-2 text-black active:bg-blue-600 font-bold uppercase text-xs  text-white py-2 px-4 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
										onClick={() => {
											handleUpload()
										}} > Upload Excel
									</button>
								</div>
							</div>
						</div>
						<div className="min-h-[450px] w-full overflow-auto  ">
							<table className="items-center border-collapse w-full  ">
								<thead>
									<tr key={'table'}>
										{propertyNames.map((column, index) => {
											return <th key={index}
												className="w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
												{displayFormat(column)}
											</th>
										})}
										<th key={propertyNames.length + 1}
											className="w-auto mx-2 px-3  dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ">
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									{items.map((item, idx) => {
										return <tr key={idx}  >
											<td className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
												{item.IssuePriority}
											</td>
											<td className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
												{item.Attachment}
											</td>
											<td className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
												{item.IssueTitle}
											</td>
											<td className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
												{item.Description}
											</td>
											<td className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
												{item.IssueStatus.Name}
											</td>
											<td className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
												{item.InspectionCard.appartmentNumber}
											</td>
											<td className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
												{item.CreatedDate}
											</td>
											<td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
												<TableDropdown activeId={item._id} item={item} tableType={'issue'} />
											</td>
										</tr>
									})}
								</tbody>
							</table>
						</div >
						<div >
							<Pagination
								pageCount={pageCount}
								pageIndex={current - 1}
							/>
						</div>
					</div >
				</>
			</div>
			{issueModalOpen === 'open' ? <AddEditIssueItem items={items} tableName="PDI_Issue_Management" columnName={columnName} /> : ""
			}
		</>

	);
}
