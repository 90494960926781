import React, { memo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { createPopper } from "@popperjs/core";
import { menu } from "../redux/menu/actions";
import { crm } from "../redux/crm/actions";

const TableDropdown = ({ activeId, item, tableType }) => {
	// dropdown props
	const dispatch = useDispatch();
	const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
	const btnDropdownRef = React.createRef();
	const popoverDropdownRef = React.createRef();
	const openDropdownPopover = () => {
		createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
			placement: "left-start",
		});
		setDropdownPopoverShow(true);
	};
	const closeDropdownPopover = () => {
		setDropdownPopoverShow(false);
	};
	const handleDelete = () => {
		dispatch(menu.trigger({ confirmation: true, activeCrudId: activeId }))
		closeDropdownPopover()
	}

	const hanldeEditTenant = () => {
		if (tableType == 'crm') {
			dispatch(crm.trigger({ checklistModalOpen: 'open', isChecklistEdit: true, activeChecklistId: activeId, checklistItem: item }))
		}
		else if (tableType == 'issue') {
			dispatch(crm.trigger({ issueModalOpen: 'open', isIssueEdit: true, activeIssueId: activeId, issueItem: item }))
		}
		else if (tableType == 'inspection') {
			dispatch(crm.trigger({ inspectionModalOpen: 'open', isInspectionEdit: true, activeInspectionId: activeId, inspectionItem: item }))
		}
		else {
			dispatch(menu.trigger({ addTenant: 'open', isChecklistEdit: false, activeCrudId: activeId, crudData: item }))
		}
		closeDropdownPopover()
	}

	return (
		<>
			<a
				className="p-2"
				href="#pablo"
				ref={btnDropdownRef}
				onClick={(e) => {
					e.preventDefault();
					dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
					setInterval(() => {
						setDropdownPopoverShow(false)
					}, 3000)
				}}
			>
				<svg className="dark:text-white feather feather-edit" fill="none" height="16" stroke="currentColor" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
			</a>
			<div
				ref={popoverDropdownRef}
				className={
					(dropdownPopoverShow ? "block " : "hidden ") +
					"z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 dark:bg-white"
				}
			>
				<a
					href="#pablo"
					className={
						"text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
					}
					onClick={(e) => handleDelete()}
				>
					Delete
				</a>
				<a
					href="#pablo"
					className={
						"text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
					}
					onClick={(e) => hanldeEditTenant()}
				>
					Edit
				</a>
			</div>
		</>
	);
};

export default TableDropdown;
