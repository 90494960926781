import * as actionTypes from './types';

export const crm = {
	trigger:
		(data) => async (dispatch) => {
			dispatch({
				type: actionTypes.CRM_ON_CHANGE,
				payload: data,
			});
		}
};