import React, { useEffect } from 'react';

import Router from './../router';
import { useSelector } from 'react-redux';
import { selectAuth } from './../redux/auth/selectors';

function App() {
	const { isLoggedIn: isLoggedIn } = useSelector(selectAuth);

	// if (!isLoggedIn) return <Router />;
	// else {
	return (
		<Router />);
	//	}
}
export default App;
