import { createSelector } from 'reselect';

const selectInspectionCards = (state) => state.inspectioncards;

export const selectCurrentItem = createSelector([selectInspectionCards], (inspectioncards) => inspectioncards.current);

export const selectListItems = createSelector([selectInspectionCards], (inspectioncards) => inspectioncards.list);

export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectInspectionCards], (inspectioncards) => inspectioncards.create);

export const selectUpdatedItem = createSelector([selectInspectionCards], (inspectioncards) => inspectioncards.update);

export const selectReadItem = createSelector([selectInspectionCards], (inspectioncards) => inspectioncards.read);

export const selectDeletedItem = createSelector([selectInspectionCards], (inspectioncards) => inspectioncards.delete);

export const selectSearchedItems = createSelector([selectInspectionCards], (inspectioncards) => inspectioncards.search);

export const selectItemsCount = createSelector([selectInspectionCards], (inspectioncards) => inspectioncards.count);

export const selectItemsFilter = createSelector([selectInspectionCards], (inspectioncards) => inspectioncards.filter);