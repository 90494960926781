import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import appartment1 from "./../../assets/img/appartment1.png"
import appartment2 from "./../../assets/img/appartment2.png"
import appartment3 from "./../../assets/img/appartment3.png"
import appartment4 from "./../../assets/img/appartment4.png"
import Plot from "./../../assets/img/The-Earth_masterPlan.jpg"
import user1 from "./../../assets/img/team-1-800x800.jpg"
import { selectListItems } from '../../redux/projectcards/selectors';
import { projectcards } from '../../redux/projectcards/actions'
import { selectCurrent } from '../../redux/menu/selectors';
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { crm } from '../../redux/crm/actions'
import { menu } from "./../../redux/menu/actions"
import AddEditProjectItem from "../../modals/AddEditProjectItem"
import Moment from 'moment';
import { useUser } from '../../contextHook/UserContext'
import { request } from '../../requestTemp/index';


export default function ProjectBoard() {
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const { result } = useSelector(selectListItems);
	const { projectReload } = useSelector(selectCurrent)
	const { projectModalOpen } = useSelector(selectCurrentCRM)
	const { items } = result;
	const { projects, setProjects, setCurrentProjectId, setCurrentProjectName } = useUser();

	const columnName = {
		"ProjectName": {},
		"BlockTower": {},
		"ProjectManager": {},
		"InspectionManager": {},
		"Location": {},
		"Details": {},
		"ProjectType": {}
	}

	const imageUrl = (idx) => {
		if (idx == 0) {
			return appartment1
		}
		else if (idx == 1) {
			return appartment2
		}
		else if (idx == 2) {
			return appartment3
		}
		else if (idx == 3) {
			return appartment4
		}
		else {
			return appartment3
		}
	}

	const handleProjectEdit = (project) => {
		dispatch(crm.trigger({ projectModalOpen: 'open', isProjectEdit: false, activeEditId: project._id, projectItem: project }))
	}

	const handleInspectionBoard = (project) => {
		setCurrentProjectId(project._id)
		setCurrentProjectName(project.projectName)
		// dispatch(crm.trigger({ activeBoardName: project._id, activeProName: project.projectName, boardType: "drag", inspectionReload: true }))
		navigate(`/inspection-crm/inspection-items?proId=${project._id}`)
	}
	const projectListing = async () => {
		let data = await request.list({ entity: 'BAF_Project', options: {} });
		if (data.success === true) {
			setProjects(data.result)
			console.log(projects, "data")
		}
	}

	useEffect(() => {
		if (projectReload) {
			projectListing()
			// dispatch(menu.trigger({ projectReload: false }))
			// dispatch(projectcards.list({ entity: 'BAF_Project', options: {} }))
		}
	}, [projectReload])

	const handleCreateProject = () => {
		dispatch(crm.trigger({ projectModalOpen: 'open', isProjectEdit: true }))
	}

	return (
		<div className="flex flex-col" >
			<div className="flex flex-row justify-between mx-10 mt-6 ">
				<div className="text-xl font-bold">Projects
				</div>
				<button
					onClick={() => handleCreateProject()}
					className="w-32 w-min-32 button px-1 bg-blue-600 text-white rounded p-2 text-xs font-bold"
				> Add Project
				</button>
			</div>
			<div className='ml-10 '>
				<div className="flex flex-wrap gap-16">
					{projects?.map((project, index) => {
						return <div className="flex rounded shadow-2xl  justify-center my-5 bg-white p-3 pt-1 " key={index}>
							<div className="">
								{project.projectName === "Plot Sale" ? <>
									<img
										className="cursor-pointer h-40 w-48 my-2  rounded-bl-md rounded-tr-none rounded-tr-md rounded-tl-md"
										src={Plot}
										alt="pro-text"
										onClick={() => handleInspectionBoard(project)}
									/>
								</> : <>
									<img
										className="cursor-pointer h-40 w-48 my-2  rounded-bl-md rounded-tr-none rounded-tr-md rounded-tl-md"
										src={imageUrl(index)}
										alt="pro-text"
										onClick={() => handleInspectionBoard(project)}
									/>
								</>}

								<div className="flex flex-row w-54">
									<div className="w-54 flex flex-col justify-around">
										<div className="flex flex-col w-54 max-w-54 ">
											<div className=" text-gray-400 text-xs">4 BHK Residential - Apartment</div>
											<div className="my-3   flex flex-row items-center justify-start w-54 text-left">
												<div className="text-gray-700 font-bold mr-3 text-left" >
													{project.projectName}<br />
												</div>
												{/* <div onClick={() => handleProjectEdit(project)}>
													<svg class="feather feather-edit" fill="none" height="16" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
														xmlns="http://www.w3.org/2000/svg">
														<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
														<path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
													</svg>
												</div> */}
											</div>
										</div>
										<div className="flex flex-col space-y-2 justify-between ">
											<div className="flex flex-row w-54 justify-between">
												<div className=" text-gray-800 text-xs">Delivery Date From :</div>
												<div className="text-black text-xs font-bold">{Moment(project.createdAt).format('MMM-DD-YYYY')}</div>
											</div>
											<div className="flex flex-row w-54 justify-between">
												<div className=" text-gray-700 text-xs">Final Delivery Date :</div>
												<div className="text-black text-xs font-bold"> {Moment(project.createdAt).format('MMM-DD-YYYY')}</div>
											</div>
										</div>
										{/* <div className="my-2 w-54 ">
											{index == 1 ? <span class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
												Pre-Delivery Stage</span> :
												(index == 0) ? <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Under Construction</span> :
													<span class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Customer Delivery Stage</span>
											}
										</div> */}
									</div>
								</div>
							</div>
						</div>
					})}
				</div>
			</div>

			{projectModalOpen === 'open' ? <AddEditProjectItem device={'mobile'} items={items} tableName="PDI_Project_Cards" columnName={columnName} /> : ""}
		</div >
	);
}
