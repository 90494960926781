import React, { useState } from "react";
import iconDown from "../assets/img/icon-chevron-down.svg";
import iconUp from "../assets/img/icon-chevron-up.svg";
import HeaderDropDown from "./Boards/HeaderDropDown";
import AddEditBoardModal from "../modals/AddEditBoardModal";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../modals/DeleteModal";
import { useNavigate } from "react-router-dom";
import { menu } from "../redux/menu/actions"
import { selectCurrentCRM } from "../redux/crm/selectors";
function IssueHeader({ setIsBoardModalOpen, isBoardModalOpen }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { activeBoardName } = useSelector(selectCurrentCRM)
	const [openDropdown, setOpenDropdown] = useState(false);
	const [isElipsisMenuOpen, setIsElipsisMenuOpen] = useState(false);
	const [boardType, setBoardType] = useState("drag");
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

	const onDropdownClick = () => {
		setOpenDropdown((state) => !state);
		setIsElipsisMenuOpen(false);
		setBoardType("add");
	};

	const onDeleteBtnClick = (e) => {
		if (e.target.textContent === "Delete") {
			// dispatch(boardsSlice.actions.deleteBoard());
			// dispatch(boardsSlice.actions.setBoardActive({ index: 0 }));
			setIsDeleteModalOpen(false);
		} else {
			setIsDeleteModalOpen(false);
		}
	};

	const handleKanbanBoard = () => {
		dispatch(menu.trigger({ tenantReload: true }))
		navigate('/inspection-crm/issue-management')
	}
	return (
		<div className="m-5 p-2 rounded left-0   z-50 right-0  ">
			<header className="flex justify-between  dark:text-white items-center  ">
				<div className=" flex items-center space-x-2  md:space-x-4">
					<div className=" flex items-center ">
						<img
							src={openDropdown ? iconUp : iconDown}
							alt=" dropdown icon"
							className=" w-3 ml-2 md:hidden"
							onClick={onDropdownClick}
						/>
					</div>
				</div>
				<div className="flex justify-start w-full mx-4 md:space-x-6" >
					<nav aria-label="breadcrumb" className="w-max" >
						<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
							<li
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<a href="#" className="opacity-60">
									<svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20"
										fill="currentColor">
										<path
											d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z">
										</path>
									</svg>
								</a>
								<span
									className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
									/
								</span>
							</li>
							<li
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<a href="#" className="opacity-60">
									<span>
										{activeBoardName}
									</span>
								</a>
								<span
									className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">/</span>
							</li>
							<li onClick={() => navigate('/inspection-crm/inspection-boards')}
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<span className="opacity-60">
									Inspection Id - 234
								</span>
								<span
									className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">/</span>
							</li>
							<li
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<a href="#">
									Issue Board
								</a>
							</li>
						</ol>
					</nav>
				</div>
				<div className="flex justify-end w-full " >
					<button className="w-36 w-min-36 button px-1 border-r-2 border-black uppercase hidden md:block rounded-r-3xl dark:bg-blue-600 p-2 py-3 text-xs font-bold"
						onClick={() => {
							navigate('/inspection-crm/issue-items')
						}} > Table View
					</button>
					<button className="w-36 w-min-36 button px-1 uppercase hidden md:block rounded-l-3xl dark:bg-blue-800 p-2 text-xs font-bold"
						onClick={() => {
							handleKanbanBoard()
						}} > Kanban Board
					</button>
				</div>
				{openDropdown && (
					<HeaderDropDown
						setOpenDropdown={setOpenDropdown}
						setIsBoardModalOpen={setIsBoardModalOpen}
					/>
				)}
			</header >
			{isBoardModalOpen && (
				<AddEditBoardModal
					setBoardType={setBoardType}
					type={boardType}
					setIsBoardModalOpen={setIsBoardModalOpen}
				/>
			)}
			{isDeleteModalOpen && (
				<DeleteModal
					setIsDeleteModalOpen={setIsDeleteModalOpen}
					type="board"
					onDeleteBtnClick={onDeleteBtnClick}
				/>
			)}
		</div >
	);
}

export default IssueHeader;
