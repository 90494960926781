import { createSelector } from 'reselect';

const selectDocument = (state) => state.document;

export const selectCurrentItem = createSelector([selectDocument], (document) => document.current);

export const selectDocumentItems = createSelector([selectDocument], (document) => document.list);


export const selectItemById = (itemId) =>
	createSelector(selectDocumentItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectDocument], (document) => document.create);

export const selectReBuildItem = createSelector([selectDocument], (document) => document.rebuild);


export const selectUpdatedItem = createSelector([selectDocument], (document) => document.update);

export const selectEFReadItem = createSelector([selectDocument], (document) => document.read);

export const selectDeletedItem = createSelector([selectDocument], (document) => document.delete);

export const selectSearchedItems = createSelector([selectDocument], (document) => document.search);

export const selectItemsCount = createSelector([selectDocument], (document) => document.count);

export const selectItemsFilter = createSelector([selectDocument], (document) => document.filter);



