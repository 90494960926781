
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Confirmation from "../../modals/Confirmation";


export default function MasterSettings() {
	const navigate = useNavigate()
	const [search, setSearch] = useState('')
	const handleSearch = (emit) => {
	}
	return <>
		<header className="flex justify-between h-24 w-full mx-auto px-5 dark:text-white items-center">
			<div className="flex flex-col justify-start w-full" >
				<nav aria-label="breadcrumb" className="w-max" >
					<ol className="flex flex-wrap items-center w-full px-4 py-1 rounded-md bg-blue-gray-50 bg-opacity-60">
						<li
							className="flex items-center font-sans text-sm antialiased font-Editrmal leading-Editrmal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
							<h3 className="text-xl font-bold">Master Settings</h3>
						</li>
					</ol>
				</nav>
				<div className="flex justify-start w-full py-2 mx-3" >
					<button onClick={() => navigate('/inspection-crm/settings')} className="w-auto mx-1 button  bg-transparent   text-black  text-opacity-40  px-3 rounded  text-xs font-bold"
					> Settings
					</button>
					<button onClick={() => navigate('/inspection-crm/master-settings')} className="w-auto button rounded  bg-indigo-100 text-blue-600   px-3 rounded text-xs font-bold"
					> Master Settings
					</button>
				</div>
			</div>
		</header>
		<Confirmation />
		<div className="flex flex-col  px-3 mx-5 h-[600px] shadow-lg rounded bg-white dark:bg-[#2b2c37]">
			<div className="flex flex-row items-center  justify-start w-full h-14 my-2  ">
				<form className="mx-3">
					<div className="relative flex w-full flex-wrap items-stretch">
						<span className="z-10 h-full leading-snug font-Editrmal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
							<i className="fas fa-search"></i>
						</span>
						<input
							type="text"
							placeholder="Search here..."
							value={search}
							onChange={(e) => handleSearch(e)}
							className="border-x border-y px-3 py-2 border-black border-opacity-40 placeholder-blueGray-300 text-grey-600 relative rounded text-sm shadow outline-Editne focus:outline-Editne focus:ring w-[300px] pl-10"
						/>
					</div>
				</form>
			</div>
			<div className="flex flex-row bg-indigo-200">
				<div className="text-xs bg-white border-x border-y border-color-black ">
					<div className="border-y border-color-black py-2 text-xl font-bold px-3">Project Type</div>
					<div className="felx flex-row space-y-3 p-3 ">
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div className="w-24">Residential</div>
							<div className="w-24">
								<span
									className="px-2 py-1 text-center  text-xs  font-semibold border-x border-y border-color rounded-xl">Edit</span>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div className="w-24">Commercial</div>
							<div className="w-24">
								<span
									className="px-2 py-1 text-center  text-xs  font-semibold border-x border-y border-color rounded-xl">Edit</span>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div className="w-24">Mixed Use</div>
							<div className="w-24">
								<span
									className="px-2 py-1 text-center  text-xs  font-semibold border-x border-y border-color rounded-xl">Edit</span>
							</div>
						</div>
					</div>
				</div>
				<div className="text-xs  bg-white border-x border-y border-color-black border-opacity-25 ">
					<div className="border-y border-color-black py-2 text-xl font-bold px-3">Type of Houses</div>
					<div className="felx flex-row space-y-3 p-3 ">
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div className="w-24">Appartment</div>
							<div className="w-24">
								<span
									className="px-2 py-1 text-center  text-xs  font-semibold border-x border-y border-color rounded-xl">Edit</span>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div className="w-24">Villa</div>
							<div className="w-24">
								<span
									className="px-2 py-1 text-center  text-xs  font-semibold border-x border-y border-color rounded-xl">Edit</span>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div className="w-24">Row House</div>
							<div className="w-24">
								<span
									className="px-2 py-1 text-center  text-xs  font-semibold border-x border-y border-color rounded-xl">Edit</span>
							</div>
						</div>
					</div>
				</div>
				<div className="text-xs  bg-white border-x border-y border-color-black border-opacity-25 ">
					<div className="border-y border-color-black py-2 text-xl font-bold px-3">Number of Bedrooms</div>
					<div className="felx flex-row space-y-3 p-3 ">
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div className="w-24">1 BHK</div>
							<div className="w-24">
								<span
									className="px-2 py-1 text-center  text-xs  font-semibold border-x border-y border-color rounded-xl">Edit</span>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div className="w-24">2 BHK</div>
							<div className="w-24">
								<span
									className="px-2 py-1 text-center  text-xs  font-semibold border-x border-y border-color rounded-xl">Edit</span>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div className="w-24">3 BHK</div>
							<div className="w-24">
								<span
									className="px-2 py-1 text-center  text-xs  font-semibold border-x border-y border-color rounded-xl">Edit</span>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div className="w-24">4 BHK</div>
							<div className="w-24">
								<span
									className="px-2 py-1 text-center  text-xs  font-semibold border-x border-y border-color rounded-xl">Edit</span>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div className="w-24">5 BHK</div>
							<div className="w-24">
								<span
									className="px-2 py-1 text-center  text-xs  font-semibold border-x border-y border-color rounded-xl">Edit</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}