import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddEditBoardModal from "../../modals/AddEditBoardModal";
import EmptyBoard from "./EmptyBoard";
import iconDown from "../../assets/img/icon-chevron-down.svg";
import iconUp from "../../assets/img/icon-chevron-up.svg";
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { selectListItems as selectStatusList } from '../../redux/inspectionstatus/selectors';
import { selectListItems } from '../../redux/inspectioncards/selectors';

import { inspectionstatus } from '../../redux/inspectionstatus/actions'
import { useNavigate } from "react-router-dom";
import ProjectChecklist from "../../modals/ProjectChecklist";
import { menu } from "../../redux/menu/actions"
import { crm } from "../../redux/crm/actions";
import HeaderDropDown from "./../../components/Boards/HeaderDropDown";
import _ from 'lodash'
import Confirmation from "../../components/Modal/Confirmation/index.js";
import TableDropdown from "../../modals/TableDropdown.js";
import { inspectioncards } from "../../redux/inspectioncards/actions";
import Pagination from "../../components/Pagination";
import AddEditInspectionItem from "../../modals/AddEditInspectionItem"


function InspectionHome() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const { result: inspectionResult } = useSelector(selectStatusList)
	const { items: status } = inspectionResult
	// const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
	const columns = status
	// const [isSideBarOpen, setIsSideBarOpen] = useState(true);
	const [color, setColor] = useState("bg-red-500")
	const [isElipsisMenuOpen, setIsElipsisMenuOpen] = useState(false);
	const [boardType, setBoardType] = useState("drag");
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [openDropdown, setOpenDropdown] = useState(false);
	const { activeBoardName, inspectionReload, inspectionModalOpen, projectChecklistOpen } = useSelector(selectCurrentCRM)
	const { result } = useSelector(selectListItems);
	const { items, pagination } = result;
	// const [colorTheme, setTheme] = useDarkMode();
	const { current, total, pageSize } = pagination;
	const pageCount = pageSize;
	const [search, setSearch] = useState('')
	const colors = [
		"bg-red-500",
		"bg-orange-500",
		"bg-blue-500",
		"bg-purple-500",
		"bg-green-500",
		"bg-indigo-500",
		"bg-yellow-500",
		"bg-pink-500",
		"bg-sky-500",
	];
	const handleSearch = (emit) => {
	}

	const handleKandbanBoard = () => {
		navigate('/inspection-crm/inspection-boards')
		dispatch(crm.trigger({ inspectionReload: true }))
	}

	const handleList = () => {
		navigate('/inspection-crm/inspection-items')
		dispatch(crm.trigger({ inspectionReload: true }))
	}

	useEffect(() => {
		dispatch(inspectionstatus.list({ entity: 'PDI_Inspection_Status', options: {} }))
	}, [])

	const onDropdownClick = () => {
		setOpenDropdown((state) => !state);
		setIsElipsisMenuOpen(false);
		setBoardType("add");
	};

	const onDeleteBtnClick = (e) => {
		if (e.target.textContent === "Delete") {
			// dispatch(boardsSlice.actions.deleteBoard());
			// dispatch(boardsSlice.actions.setBoardActive({ index: 0 }));
			setIsDeleteModalOpen(false);
		} else {
			setIsDeleteModalOpen(false);
		}
	};

	// useEffect(() => {
	// 	setColor(shuffle(colors).pop())
	// }, [dispatch]);

	const handleOnDrop = (e) => {
		// const { prevColIndex, taskIndex } = JSON.parse(
		// 	e.dataTransfer.getData("text")
		// );

		// if (colIndex !== prevColIndex) {
		// 	dispatch(
		// 		boardsSlice.actions.dragTask({ colIndex, prevColIndex, taskIndex })
		// 	);
		// }
	};

	const displayFormat = (data) => {
		return _.startCase(data)
	}

	const handleProject = () => {
		navigate('/inspection-crm/project-boards')
	}

	useEffect(() => {
		if (inspectionReload) {
			dispatch(inspectioncards.list({ entity: 'PDI_Inspection_Items', options: { page: 1, limit: 5 } }))
			dispatch(crm.trigger({ inspectionReload: false }))
		}
	}, [inspectionReload])

	useEffect(() => {
		dispatch(inspectioncards.list({ entity: 'PDI_Inspection_Items', options: { page: 1, limit: 5 } }))
	}, [])

	const handleOnDrag = (e) => {
		// e.dataTransfer.setData(
		// 	"text",
		// 	JSON.stringify({ taskIndex, prevColIndex: colIndex })
		// );
	};

	const handleOnDragOver = (e) => {
		e.preventDefault();
	};

	const handleIssueStatus = () => {
		dispatch(menu.trigger({ tenantReload: true }))
		navigate('/inspection-crm/issue-items')
	}

	const handleInspectionList = (projectType) => {
		dispatch(crm.trigger({ projectChecklistOpen: 'open', checklistProjectType: projectType }))
	}

	const handleCustomerEdit = () => {

	}

	const handleProjectChecklist = (obj) => {
		navigate("/inspection-crm/project-checklist")
		dispatch(crm.trigger({ activeCustomer: obj }))
	}

	return (
		<>
			<div className="flex flex-col item-center">
				<header className="flex justify-between h-20 w-full mx-auto px-5 dark:text-white items-center">
					<div className="flex justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<h3 className="text-xl font-bold" onClick={() => handleProject()}>Projects</h3>
									<span
										className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
										<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#" className="opacity-60 text-md font-bold">
										<span>
											{activeBoardName}
										</span>
									</a>
									<span
										className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
										<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#" className="opacity-60 text-md font-bold">
										Customer Details
									</a>
									<span
										className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
									</span>
								</li>
							</ol>
						</nav>
					</div>
					<div className="flex justify-end w-full" >
						<button className="w-auto mx-3 button ring-1 ring-insert ring-blue-600 bg-transparent text-blue-600 px-3 rounded p-2 text-xs font-bold"
							onClick={() => {
								handleKandbanBoard()
							}} > + Add Customer
						</button>
						<button className="w-auto mx-3 button rounded dark:bg-blue-600 p-2 text-xs font-bold"
							onClick={() => {
								handleKandbanBoard()
							}} > + Import Customer
						</button>
					</div>
				</header >
				<div>
					<Confirmation />
					<div className="flex flex-col  px-3 mx-5 h-[600px]  dark:bg-[#2b2c37]">
						<div className="flex flex-row items-center  justify-start w-full h-14 my-2   ">
							<form className="mx-3">
								<div className="relative flex w-full flex-wrap items-stretch">
									<span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
										<i className="fas fa-search"></i>
									</span>
									<input
										type="text"
										placeholder="Search here..."
										value={search}
										onChange={(e) => handleSearch(e)}
										className="border-x border-y px-3 py-2 border-black border-opacity-40 placeholder-blueGray-300 text-grey-600 relative rounded text-sm shadow outline-none focus:outline-none focus:ring w-[300px] pl-10"
									/>
								</div>
							</form>
							<div className="flex justify-end w-36 mx-3 space-x-3" >
								<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full"
										onChange={(e) => console.log('ok')} >
										<option value="1" key={1}>Stage</option>
									</select>
								</div>
							</div>
							<div className="flex justify-end w-36  mx-3 space-x-3" >
								<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full"
										onChange={(e) => console.log('ok')} >
										<option value="1" key={1}>Status</option>
									</select>
								</div>
							</div>
							<div className="flex justify-end w-36 space-x-3" >
								<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full"
										onChange={(e) => console.log('ok')} >
										<option value="1" key={1}>Filter</option>
									</select>
								</div>
							</div>
							<div className="flex justify-end w-36 mx-auto space-x-3 mr-0" >
								<button className="w-auto px-3 button bg-transparent rounded ring-1 ring-inset ring-blue-600 text-blue-600 p-2 text-xs font-bold"
								> + Assign
								</button>
							</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										onClick={() => handleList()}
										className="px-4 py-2 w-20 text-center text-sm font-semibold rounded-l-md bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">List</span>
									<span
										onClick={() => {
											handleKandbanBoard()
										}}
										className="px-4 py-2 w-20 text-center  text-sm  font-semibold rounded-r-md bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">Kanban</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row">
							{columns.length > 0 ? (
								<>
									{columns.map((col, index) => (
										<div
											onDrop={handleOnDrop}
											onDragOver={handleOnDragOver}
											className="scrollbar-hide shadow-lg rounded px-5  mx-auto pt-[20px] min-w-[280px]">
											<p className=" font-semibold flex  items-center  gap-2 tracking-widest md:tracking-[.2em] text-[#828fa3]">
												<div className={`rounded-full w-4 h-4 ${color} `} />
												{col.Name}
											</p>
											{items.map((item, idx) => {
												if (item.InspectionStatus == col.Name) {
													return <div className="bg-white"
													>
														<div
															draggable
															onDragStart={handleOnDrag}
															className=" w-[280px] first:my-5 rounded-lg    dark:bg-[#2b2c37]  py-3 px-3  hover:text-[#635fc7] dark:text-white dark:hover:text-[#635fc7]"
														>
															<div className="h-40 items-center">
																<div className="text-sm font-bold mb-1  cursor-pointer" onClick={() => handleProjectChecklist(item.CustomerName)}>{item["appartmentNumber"]},
																	{item["blockTower"]},
																	{item["TypeOfHouse"]}
																</div>
																<div className="flex flex-row justify-between">
																	<div className="text-sm my-1">
																		{item["CustomerName"]} - {item["Customer Contacts"]}

																	</div>
																	<div onClick={() => handleCustomerEdit(item)}>
																		<svg class="feather feather-edit" fill="none" height="16" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
																			xmlns="http://www.w3.org/2000/svg">
																			<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
																			<path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
																		</svg>
																	</div>
																</div>
																<div className="flex flex-row my-2">
																	<div>
																		<button className="inline-flex items-center mx-2 rounded-md bg-green-300 px-2 py-1 text-xs font-medium text-blue-500 ring-1 ring-inset ring-blue-600" >
																			PDI Stage
																		</button>
																	</div>
																	<div>
																		{item["Status"] == 'Todo' ?
																			<button className="inline-flex items-center mx-2 rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-blue-500 ring-1 ring-inset ring-blue-600" >
																				{item["Status"]}
																			</button> :
																			item["Status"] == 'Doing' ? <button className="inline-flex items-center mx-2 rounded-md bg-transparent px-2 py-1 text-xs font-medium text-red-500 ring-1 ring-inset ring-red-600" >
																				{item["Status"]}
																			</button> : <button className="inline-flex items-center mx-2 rounded-md bg-transparent px-2 py-1 text-xs font-medium text-green-500 ring-1 ring-inset ring-green-600" >
																				{item["Status"]}
																			</button>
																		}
																	</div>
																</div>
																<div className="flex flex-row my-2" onClick={() => navigate('/inspection-crm/issues-management')}>
																	<button className="inline-flex items-center mx-2 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-600" >
																		2 out of 7 Issue Completed
																	</button>
																</div>
																<div className="flex flex-row my-2  items-baseline">
																	<div className="flex flex-row items-center justify-around"
																		onClick={() => handleCustomerEdit(item)}>
																		<svg fill="#D07C40" version="1.1" id="Capa_1"
																			width="18px" height="18px" viewBox="0 0 485.87 485.869"
																		>
																			<path d="M41.766,378.467h260.393v107.402l99.884-107.402h42.061V0H41.766V378.467z M376.792,158.709
			c18.829,0,34.091,15.264,34.091,34.09c0,18.83-15.265,34.09-34.091,34.09s-34.091-15.264-34.091-34.09
			C342.703,173.971,357.964,158.709,376.792,158.709z M287.554,158.709c18.828,0,34.09,15.264,34.09,34.09
			c0,18.83-15.264,34.09-34.09,34.09c-18.829,0-34.093-15.264-34.093-34.09C253.461,173.971,268.725,158.709,287.554,158.709z
			 M198.314,158.709c18.829,0,34.091,15.264,34.091,34.09c0,18.83-15.264,34.09-34.091,34.09c-18.83,0-34.09-15.264-34.09-34.09
			C164.224,173.971,179.485,158.709,198.314,158.709z M109.074,158.709c18.83,0,34.092,15.264,34.092,34.09
			c0,18.83-15.265,34.09-34.092,34.09c-18.825,0-34.09-15.264-34.09-34.09C74.984,173.971,90.246,158.709,109.074,158.709z"/>
																		</svg>
																		<span>3</span>
																	</div>
																	<div className="flex flex-row items-center justify-around mx-2"
																		onClick={() => handleCustomerEdit(item)}>
																		<svg height="18px" width="18px" version="1.1" id="Layer_1"
																			viewBox="0 0 280.067 280.067" >
																			<path fill="#D07C40" d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429
		c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0
		s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394
		c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599
		c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403
		c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485
		c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098
		l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z"/>
																		</svg>
																		<span>5</span>
																	</div>
																	<div className="flex items-center justify-end w-full -mr-4 ">
																		<img
																			className="h-10 w-10 -mt-3 rounded-full"
																			src="https://manuarora.in/avatar.jpg"
																			alt="avatar"
																		/>
																	</div>
																	<div className="flex items-center justify-end w-12 ">
																		<img
																			className="h-10 w-10 -mt-3  rounded-full"
																			src="https://manuarora.in/avatar.jpg"
																			alt="avatar"
																		/>
																	</div>
																</div>
															</div>
														</div>
														{(projectChecklistOpen == 'open') ?
															<ProjectChecklist
																type="add"
																device="mobile"
															/> : <></>
														}
													</div >
												}
											})}
										</div>
									))}
								</>
							) : (
								<>
									<EmptyBoard type="edit" />
								</>
							)
							}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default InspectionHome;
