import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { crm } from "../redux/crm/actions";
import { useUser } from '../contextHook/UserContext'
function DeleteModal() {
    const { projectItem, userModelOpen } = useSelector(selectCurrentCRM)
    const dispatch = useDispatch();
    const { custumerModel, setCustumerModel, currentProjects, curretProjectName } = useUser();
    const handleClose = () => {
        // dispatch(crm.trigger({ userModelOpen: 'close' }))
        setCustumerModel('close')
    }
    const totCost = (data) => {
        const inputString = String(data).replace(/[^\d]/g, ''); // Convert to string and remove non-numeric characters
        const sanitizedValue = parseInt(inputString, 10); // Parse the value as an integer
        return (new Intl.NumberFormat('en-US').format(sanitizedValue))
    }

    return (
        <div
            // onClick={(e) => {
            // 	if (e.target !== e.currentTarget) {
            // 		return;
            // 	}
            // 	setIsDeleteModalOpen(false);
            // }}
            className="fixed right-0 top-0 px-2 py-4 overflow-scroll scrollbar-hide  z-50 left-0 bottom-0 justify-center items-center flex dropdown"
        >
            <div className=" scrollbar-hide overflow-y-scroll max-h-[95vh]  my-auto  bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold shadow-md shadow-[#364e7e1a] max-w-[90%] mx-auto  w-full px-8  py-8 rounded-xl ">
                <div className="flex justify-between">
                    <h3 className=" font-bold  text-xl text-blue-500 underline">
                        Customer Details
                    </h3>
                    <button
                        onClick={() => {
                            handleClose()
                        }}
                        className="p-4 items-center text-[#635fc7] dark:bg-white hover:opacity-75 bg-[#635fc71a]  py-2 rounded-full"
                    >
                        X
                    </button>

                </div>

                <div className="mt-4">
                    <h6 className="text-blue-500 underline">Personal Details:</h6>
                </div>
                <div className="w-full mt-2">
                    <div className='grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-3 gap-16 mt-2'>
                        <div className="flex flex-wrap justify-between">
                            <h3>Name: &nbsp;</h3>  <p className="font-normal">{currentProjects.primaryApplicantId.fname}  </p>
                        </div>
                        <div className="flex flex-wrap justify-between">
                            <h3>Father Name: &nbsp;</h3>  <p className="font-normal">{currentProjects.primaryApplicantId.fatherName}</p>
                        </div>
                        <div className="flex flex-wrap justify-between">
                            <h3>Mobile No: &nbsp;</h3>  <p className="font-normal">{currentProjects.primaryApplicantId.mobileNumber ? currentProjects.primaryApplicantId.mobileNumber : ""}  </p>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-3 gap-16 mt-2'>

                        <div className="flex flex-wrap justify-between">
                            <h3>DOB: &nbsp;</h3>  <p className="font-normal">{currentProjects.primaryApplicantId.dob}</p>
                        </div>
                        <div className="flex flex-wrap justify-between">
                            <h3>Aadhar Number: &nbsp;</h3>  <p className="font-normal">{currentProjects.primaryApplicantId.aadharNumber}</p>
                        </div>
                        <div className="flex flex-wrap justify-between">
                            <h3>Pan No: &nbsp;</h3>  <p className="font-normal">{currentProjects.primaryApplicantId.panNumber}  </p>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-3 gap-16 mt-2'>

                        <div className="flex flex-wrap justify-between">
                            <h3>Address: &nbsp;</h3>
                            <div>
                                <p className="font-normal text-right">{currentProjects.primaryApplicantId.permanentAddress ? currentProjects.primaryApplicantId.permanentAddress.addressLine1 : ""}</p>
                                <p className="font-normal text-right">{currentProjects.primaryApplicantId.permanentAddress ? currentProjects.primaryApplicantId.permanentAddress.addressLine2 : ""}</p>
                                <p className="font-normal text-right">{currentProjects.primaryApplicantId.permanentAddress ? currentProjects.primaryApplicantId.permanentAddress.city : ""}</p>

                                <p className="font-normal text-right">{currentProjects.primaryApplicantId.permanentAddress ? currentProjects.primaryApplicantId.permanentAddress.state : ""}</p>

                                <p className="font-normal text-right">{currentProjects.primaryApplicantId.permanentAddress ? currentProjects.primaryApplicantId.permanentAddress.country : ""}</p>
                            </div>

                        </div>

                    </div>

                </div>
                {curretProjectName === "Plot Sale" ? <>
                    <div className="mt-6">
                        <h6 className="text-blue-500 underline">Plot Details:</h6>
                    </div>


                </>


                    : <>
                        <div className="mt-6">
                            <h6 className="text-blue-500 underline">Apartment Details:</h6>
                        </div></>}
                {curretProjectName === "Plot Sale" ? <>
                    <div className="w-full mt-2">
                        <div className='grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-3 gap-16 mt-2'>
                            {/* <div className="flex flex-wrap justify-between">
                                <h3>Project Name: &nbsp;</h3>  <p className="font-normal">{currentProjects.apartmentId.projectName.projectName}  </p>
                            </div> */}
                            <div className="flex flex-wrap justify-between">
                                <h3>Plot Number: &nbsp;</h3>  <p className="font-normal">{currentProjects.apartmentId.plotNo.entityName}</p>
                            </div>
                            <div className="flex flex-wrap justify-between">
                                <h3>Rate per Sqft in Rs (₹): &nbsp;</h3>  <p className="font-normal">{totCost(currentProjects.apartmentId.ratePerSqft)}  </p>
                            </div>
                            <div className="flex flex-wrap justify-between">
                                <h3>Total Cost in Rs (₹): &nbsp;</h3>  <p className="font-normal">{totCost(currentProjects.apartmentId.totalCost)}  </p>
                            </div>
                        </div>
                        {/* <div className='grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-3 gap-16 mt-2'>

                        <div className="flex flex-wrap justify-between">
                                <h3>Apartment Type: &nbsp;</h3>  <p className="font-normal">{currentProjects.apartmentId.plotNo}</p>
                            </div>
                            <div className="flex flex-wrap justify-between">
                                <h3>Rate per Sqft in Rs (₹): &nbsp;</h3>  <p className="font-normal">{totCost(currentProjects.apartmentId.ratePerSqft)}</p>
                            </div>
                            <div className="flex flex-wrap justify-between">
                                <h3>Total Cost in Rs (₹): &nbsp;</h3>  <p className="font-normal">{totCost(currentProjects.apartmentId.totalCost)}  </p>
                            </div>
                        </div> */}


                    </div>

                </> : <>
                    <div className="w-full mt-2">
                        <div className='grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-3 gap-16 mt-2'>
                            <div className="flex flex-wrap justify-between">
                                <h3>Project Name: &nbsp;</h3>  <p className="font-normal">{currentProjects.apartmentId.projectName.projectName}  </p>
                            </div>
                            <div className="flex flex-wrap justify-between">
                                <h3>Apartment Type: &nbsp;</h3>  <p className="font-normal">{currentProjects.apartmentId.apartmentType.entityName}</p>
                            </div>
                            <div className="flex flex-wrap justify-between">
                                <h3>Apartment Block: &nbsp;</h3>  <p className="font-normal">{currentProjects.apartmentId.apartmentBlock.entityName}  </p>
                            </div>
                        </div>
                        <div className='grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-3 gap-16 mt-2'>

                            <div className="flex flex-wrap justify-between">
                                <h3>Apartment No: &nbsp;</h3>  <p className="font-normal">{currentProjects.apartmentId.apartmentNo.entityName}</p>
                            </div>
                            <div className="flex flex-wrap justify-between">
                                <h3>Rate per Sqft in Rs (₹): &nbsp;</h3>  <p className="font-normal">{totCost(currentProjects.apartmentId.ratePerSqft)}</p>
                            </div>
                            <div className="flex flex-wrap justify-between">
                                <h3>Total Cost in Rs (₹): &nbsp;</h3>  <p className="font-normal">{totCost(currentProjects.apartmentId.totalCost)}  </p>
                            </div>
                        </div>


                    </div>

                </>}

                <div className="mt-6">
                    <h6 className="text-blue-500 underline">Booking Details:</h6>
                </div>
                <div className="w-full mt-2 mb-6">
                    <div className='grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-3 gap-16 mt-2'>
                        <div className="flex flex-wrap justify-between">
                            <h3>Booking Amount: &nbsp;</h3>  <p className="font-normal">{currentProjects.bookingAmount}  </p>
                        </div>
                        <div className="flex flex-wrap justify-between">
                            <h3>Payment Details: &nbsp;</h3>  <p className="font-normal">{currentProjects.paymentDetails}</p>
                        </div>

                    </div>



                </div>
            </div>
        </div>
    );
}

export default DeleteModal;
