import { createSelector } from 'reselect';

const selectIssueStatus = (state) => state.issuestatus;

export const selectCurrentItem = createSelector([selectIssueStatus], (issuestatus) => issuestatus.current);

export const selectListItems = createSelector([selectIssueStatus], (issuestatus) => issuestatus.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectIssueStatus], (issuestatus) => issuestatus.create);

export const selectUpdatedItem = createSelector([selectIssueStatus], (issuestatus) => issuestatus.update);

export const selectReadItem = createSelector([selectIssueStatus], (issuestatus) => issuestatus.read);

export const selectDeletedItem = createSelector([selectIssueStatus], (issuestatus) => issuestatus.delete);

export const selectSearchedItems = createSelector([selectIssueStatus], (issuestatus) => issuestatus.search);

export const selectItemsCount = createSelector([selectIssueStatus], (issuestatus) => issuestatus.count);

export const selectItemsFilter = createSelector([selectIssueStatus], (issuestatus) => issuestatus.filter);



