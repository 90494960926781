import { crm } from "../redux/crm/actions";
import { menu } from "../redux/menu/actions";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { selectListItems } from '../redux/projecttype/selectors';
import { request } from '../requestTemp/index';
import { crud } from "../redux/crud/actions";
import _ from 'lodash'
import { useUser } from '../contextHook/UserContext'
import { projecttype } from "../redux/projecttype/actions";

export default function AddEditProjectItem({ items, tableName, columnName }) {
	const dispatch = useDispatch();
	const propertyNames = Object.keys(columnName);
	const { projectModalOpen, activeEditId, isProjectEdit, projectItem } = useSelector(selectCurrentCRM);
	const { result } = useSelector(selectListItems)
	const { items: _items } = result
	const [steps, setSteps] = useState({});
	const [steps1, setSteps1] = useState(projectItem);
	const [device, setDevice] = useState('mobile')
	const [proName, setProName] = useState('')
	const [tabs, setTabs] = useState('project')
	const [proStatus, setProStatus] = useState('init')
	const [apartStatus, setApartStatus] = useState('init')

	const [aparType, setAparType] = useState([])
	const [aparBlock, setAparBlock] = useState([])
	const [aparNo, setAparNo] = useState([])

	const [valuePro, setValuePro] = useState("")
	const [valueType, setValueType] = useState("")
	const [valueBlock, setValueBlock] = useState("")
	const [valueNo, setValueNo] = useState("")

	const [area, setArea] = useState("")
	const [rate, setRate] = useState("")
	const [cost, setCost] = useState("")


	const { setProjects, projects } = useUser();


	const displayFormat = (data) => {
		return _.startCase(data)
	}

	const handleClose = async () => {
		dispatch(crm.trigger({ projectModalOpen: 'close' }))
		let data = await request.list({ entity: 'BAF_Project', options: {} });
		if (data.success === true) {
			setProjects(data.result)
			// console.log(projects, "data")
		}
	}

	const handleSave = async () => {
		const jsonData = {
			"projectName": proName,
			"status": "Draft"

		}
		let data = await request.create({ entity: 'BAF_Project', jsonData });
		if (data.success === true) {
			setProStatus('finished')
			setProName('')
		}

	}

	const handleSteps1 = (e, key) => {
		setSteps1({ ...steps1, [key]: e.target.value })
	}

	const handleSteps = (e, key) => {
		setSteps({ ...steps, [key]: e.target.value })
	}
	const apertTypeListing = async () => {
		let data = await request.listCustom({ entity: 'apartmenttype' });
		if (data) {
			setAparType(data)
			console.log(data, "data")
		}
	}
	const apertBlockListing = async () => {
		let data = await request.listCustom({ entity: 'apartmentblock' });
		if (data) {
			setAparBlock(data)
			console.log(data, "data")
		}
	}
	const apertNoListing = async () => {
		let data = await request.listCustom({ entity: 'apartmentno' });
		if (data) {
			setAparNo(data)
			console.log(data, "data")
		}
	}
	const handleAddApartment = async () => {
		const jsonData = {
			"entity": {
				"projectName": valuePro,
				"apartmentType": valueType,
				"apartmentBlock": valueBlock,
				"apartmentNo": valueNo,
				"saleableArea": area,
				"ratePerSqft": rate,
				"totalCost": cost
			}
		}
		let data = await request.create({ entity: 'BAF_Apartment', jsonData });
		if (data.success === true) {
			setApartStatus('finished')
			setProName('')
		}
	}

	useEffect(() => {
		apertTypeListing()
		apertBlockListing()
		apertNoListing()
		// dispatch(projecttype.list({ entity: 'PDI_Project_Configuration', options: {} }))
	}, [])
	return (
		<div className="fixed right-0 top-0 px-2 py-4 overflow-scroll scrollbar-hide  z-50 left-0 bottom-0 justify-center items-center flex dropdown">
			{projectModalOpen == 'open' ? (
				<div
					className={
						device === "mobile"
							? "  py-6 px-6 pb-40  absolute  left-0 flex  right-0  top-0"
							: "  py-6 px-6 pb-40  absolute   left-0 flex  right-0 bottom-0 top-0 dropdown "
					}
					onClick={(e) => {
						if (e.target !== e.currentTarget) {
							return;
						}
					}}
				>
					<div
						className="bg-indigo-100 dark:bg-[#2b2c37] text-black dark:text-white font-bold max-w-full mx-auto  px-8  py-8 rounded-xl">

						<div className="flex justify-around">
							<div className={`cursor-pointer ${tabs === "project" ? 'opacity-100' : 'opacity-40'}`} onClick={(e) => setTabs("project")}>
								<h6 className="text-sm border-b-2 border-black pb-3 px-2">
									Add Project
								</h6>
							</div>
							<div className={`cursor-pointer ${tabs === "type" ? 'opacity-100' : 'opacity-40'}`} onClick={(e) => setTabs("type")}>
								<h6 className="text-sm border-b-2 border-black pb-3 px-2">
									Add Apartments
								</h6>
							</div>
							{/* <div className={`cursor-pointer ${tabs === "block" ? 'opacity-100' : 'opacity-30'}`} onClick={(e) => setTabs("block")}>
								<h6 className="text-sm border-b-2 border-black pb-3 px-2">
									Apartments Block
								</h6>
							</div> */}
							{/* <div className={`cursor-pointer ${tabs === "number" ? 'opacity-100' : 'opacity-30'}`} onClick={(e) => setTabs("number")}>
								<h6 className="text-sm border-b-2 border-black pb-3 px-2">
									Apartments No.
								</h6>
							</div> */}

						</div>

						{isProjectEdit ? <div className="w-full px-4 mt-6 overflow-auto max-h-[65vh]">
							{tabs === "project" ? <>
								{proStatus === "init" ? <>
									<div className="relative w-full mb-3">
										<label
											className="block uppercase text-xs font-bold mb-2"
											htmlFor="grid-password">
											{displayFormat(propertyNames[0])}
										</label>
										<input
											type="text"
											name={"step_0"}
											className="border-0 px-3 py-3 dark:text-indigo-900 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											onChange={(e) => setProName(e.target.value)}
											value={proName}
										/>
									</div>
									<div className="mt-8 flex flex-row justify-between border-t-2 border-black pt-3">
										<button
											className="w-32 w-min-32 button px-1 bg-blue-600 text-white rounded p-2 text-xs font-bold mr-2"
											onClick={() => {
												handleClose()
											}}
										> Close</button>
										<button
											className="w-32 w-min-32 button px-1 bg-blue-600 text-white rounded p-2 text-xs font-bold"
											onClick={() => handleSave()}>
											{isProjectEdit ? "Add" : "Update"}
										</button>
									</div>

								</> : <>
									<div className="rounded-xl">
										<h6 className="text-sm pb-2 px-2">
											Project Addded !
										</h6>
										<h6 className="text-sm pb-2 px-2 underline" onClick={() => setProStatus("init")}>Add another new project</h6>
									</div>

								</>}


							</> : tabs === "type" ? <>
								{apartStatus === "init" ? <>
									<div className="relative w-full mb-3">
										<label
											className="block uppercase text-xs font-bold mb-2"
											htmlFor="grid-password" >
											Project Name
										</label>
										<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
											<select
												className="w-full py-1"
												value={valuePro}
												onChange={(e) => setValuePro(e.target.value)}
											>
												<option value="" selected hidden>Select Project</option>
												{projects.map((pro, index) => {
													return <option value={pro._id} key={index}> {pro.projectName}</option>
												})}
											</select>
										</div>
									</div>
									<div className="relative w-full mb-3 mt-4">
										<label
											className="block uppercase text-xs font-bold mb-2"
											htmlFor="grid-password" >
											Apartment Type
										</label>
										<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
											<select
												className="w-full py-1"
												value={valueType}
												onChange={(e) => setValueType(e.target.value)}
											>
												<option value="" selected hidden>Select Apartment Type</option>
												{aparType.map((item, index) => {
													return <option value={item._id} key={index}> {item.entityName}</option>
												})}
											</select>
										</div>
									</div>
									<div className="relative w-full mb-3 mt-4">
										<label
											className="block uppercase text-xs font-bold mb-2"
											htmlFor="grid-password" >
											Apartment Block
										</label>
										<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
											<select
												className="w-full py-1"
												value={valueBlock}
												onChange={(e) => setValueBlock(e.target.value)}
											>
												<option value="" selected hidden>Select Apartment Block</option>
												{aparBlock.map((item, index) => {
													return <option value={item._id} key={index}> {item.entityName}</option>
												})}
											</select>
										</div>
									</div>
									<div className="relative w-full mb-3 mt-4">
										<label
											className="block uppercase text-xs font-bold mb-2"
											htmlFor="grid-password" >
											Apartment Number
										</label>
										<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
											<select
												className="w-full py-1"
												value={valueNo}
												onChange={(e) => setValueNo(e.target.value)}
											>
												<option value="" selected hidden>Select Apartment No.</option>
												{aparNo.map((item, index) => {
													return <option value={item._id} key={index}> {item.entityName}</option>
												})}
											</select>
										</div>
									</div>
									<div className="relative w-full mb-3">
										<label
											className="block uppercase text-xs font-bold mb-2"
											htmlFor="grid-password">
											Saleable Area
										</label>
										<input
											type="text"
											name={"step_0"}
											className="border-0 px-3 py-3 dark:text-indigo-900 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											value={area}
											onChange={(e) => setArea(e.target.value)}

										/>
									</div>
									<div className="relative w-full mb-3">
										<label
											className="block uppercase text-xs font-bold mb-2"
											htmlFor="grid-password">
											Rate Per Sqft in Rs.
										</label>
										<input
											type="text"
											name={"step_0"}
											className="border-0 px-3 py-3 dark:text-indigo-900 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											onChange={(e) => setRate(e.target.value)}
											value={rate}
										/>
									</div>
									<div className="relative w-full mb-3">
										<label
											className="block uppercase text-xs font-bold mb-2"
											htmlFor="grid-password">
											Total Cost in Rs.
										</label>
										<input
											type="text"
											name={"step_0"}
											className="border-0 px-3 py-3 dark:text-indigo-900 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											onChange={(e) => setCost(e.target.value)}
											value={cost}
										/>
									</div>
									<div className="mt-8 flex flex-row justify-between border-t-2 border-black pt-3">
										<button
											className="w-32 w-min-32 button px-1 bg-blue-600 text-white rounded p-2 text-xs font-bold mr-2"
											onClick={() => {
												handleClose()
											}}
										> Close</button>
										<button
											className="w-32 w-min-32 button px-1 bg-blue-600 text-white rounded p-2 text-xs font-bold"
											onClick={() => handleAddApartment()}>
											{isProjectEdit ? "Add" : "Update"}
										</button>
									</div>

								</> : <>
									<div className="rounded-xl">
										<h6 className="text-sm pb-2 px-2">
											Apartment Addded !
										</h6>
										<h6 className="text-sm pb-2 px-2 underline" onClick={() => setApartStatus("init")}>Add another Apartment</h6>
									</div>

								</>}



							</> : <></>}

							{/* <div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password">
									{displayFormat(propertyNames[1])}
								</label>
								<input
									type="text"
									name={"step_1"}
									className="border-0 px-3 py-3 dark:text-indigo-900 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps(e, propertyNames[1])}
									value={steps[propertyNames[1]]}
								/>
							</div> */}
							{/* <div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password" >
									{displayFormat(propertyNames[6])}
								</label>
								<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full py-1"
										onChange={(e) => handleSteps1(e, propertyNames[6])}
										defaultValue={steps1[propertyNames[6]]}>
										{_items.map((item, index) => {
											return <option value={item._id} key={index}> {item.Name}</option>
										})}
									</select>
								</div>
							</div> */}
							{/* <div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password">
									{displayFormat("Delivery Date From")}
								</label>
								<input
									type="date"
									name={"step_1"}
									className="border-0 px-3 py-3 dark:text-indigo-900 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps(e, propertyNames[1])}
									value={steps[propertyNames[1]]}
								/>
							</div>

							<div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password">
									{displayFormat("Final Delivery Date")}
								</label>
								<input
									type="date"
									name={"step_1"}
									className="border-0 px-3 py-3 dark:text-indigo-900 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps(e, propertyNames[1])}
									value={steps[propertyNames[1]]}
								/>
							</div> */}
							{/* <div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password" >
									{displayFormat(propertyNames[5])}
								</label>
								<textarea
									type="text"
									name={"step_4"}
									className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps1(e, propertyNames[5])}
									value={steps1[propertyNames[5]]}
								/>
							</div> */}
							{/* <div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password">
									{displayFormat(propertyNames[4])}
								</label>
								<input
									type="text"
									name={"step_4"}
									className="border-0 px-3 py-3 dark:text-indigo-900 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps(e, propertyNames[4])}
									value={steps[propertyNames[4]]}
								/>
							</div> */}
							{/* <div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password">
									{displayFormat(propertyNames[2])}
								</label>
								<input
									type="text"
									name={"step_2"}
									className="border-0 px-3 py-3 dark:text-indigo-900 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps(e, propertyNames[2])}
									value={steps[propertyNames[2]]}
								/>
							</div> */}
							{/* <div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password">
									{displayFormat(propertyNames[3])}
								</label>
								<input
									type="text"
									name={"step_3"}
									className="border-0 px-3 py-3 dark:text-indigo-900 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps(e, propertyNames[3])}
									value={steps[propertyNames[3]]}
								/>
							</div> */}
							{/* <div className="relative w-full mb-3">
								<label
									htmlFor="formFile"
									className="block uppercase text-xs font-bold mb-2" >
									Image URL
								</label>
								<input
									className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-black bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
									type="file"
									id="formFile"
								/>
							</div> */}
						</div> : <div className="w-full lg:w-12/12 px-4 mt-6 overflow-auto max-h-[65vh]">
							{/* <div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password" >
									{displayFormat(propertyNames[0])}
								</label>
								<input
									type="text"
									name={"step_0"}
									className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps1(e, propertyNames[0])}
									value={steps1[propertyNames[0]]}
								/>
							</div>
							<div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password">
									{displayFormat(propertyNames[1])}
								</label>
								<input
									type="text"
									name={"step_1"}
									className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps1(e, propertyNames[1])}
									value={steps1[propertyNames[1]]}
								/>
							</div>
							<div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password" >
									{displayFormat(propertyNames[6])}
								</label>
								<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full py-1"
										onChange={(e) => handleSteps1(e, propertyNames[6])}
										defaultValue={steps1[propertyNames[6]]}>
										{_items.map((item, index) => {
											return <option value={item._id} key={index}> {item.Name}</option>
										})}
									</select>
								</div>
							</div>
							<div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password" >
									{displayFormat(propertyNames[5])}
								</label>
								<input
									type="text"
									name={"step_5"}
									className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps1(e, propertyNames[5])}
									value={steps1[propertyNames[5]]}
								/>
							</div>
							<div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password" >
									{displayFormat(propertyNames[4])}
								</label>
								<input
									type="text"
									name={"step_4"}
									className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps1(e, propertyNames[4])}
									value={steps1[propertyNames[4]]}
								/>
							</div>
							<div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password" >
									{displayFormat(propertyNames[2])}
								</label>
								<input
									type="text"
									name={"step_2"}
									className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps1(e, propertyNames[2])}
									value={steps1[propertyNames[2]]}
								/>
							</div>
							<div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password" >
									{displayFormat(propertyNames[3])}
								</label>
								<input
									type="text"
									name={"step_3"}
									className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps1(e, propertyNames[3])}
									value={steps1[propertyNames[3]]}
								/>
							</div>

							<div className="relative w-full mb-3">
								<label
									htmlFor="formFile"
									className="block uppercase text-xs font-bold mb-2" >
									Image URL
								</label>
								<input
									className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
									type="file"
									id="formFile"
								/>
							</div> */}
						</div>
						}

					</div>
				</div >
			) : null
			}
		</div>
	);
}

