import { createSelector } from 'reselect';

const selectUsers = (state) => state.users;

export const selectCurrentItem = createSelector([selectUsers], (users) => users.current);

export const selectListItems = createSelector([selectUsers], (users) => users.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectUsers], (users) => users.create);

export const selectUpdatedItem = createSelector([selectUsers], (users) => users.update);

export const selectReadItem = createSelector([selectUsers], (users) => users.read);

export const selectDeletedItem = createSelector([selectUsers], (users) => users.delete);

export const selectSearchedItems = createSelector([selectUsers], (users) => users.search);

export const selectItemsCount = createSelector([selectUsers], (users) => users.count);

export const selectItemsFilter = createSelector([selectUsers], (users) => users.filter);



