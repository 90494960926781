import { createSelector } from 'reselect';

const selectCategoryName = (state) => state.categoryname;

export const selectCurrentItem = createSelector([selectCategoryName], (categoryname) => categoryname.current);

export const selectListItems = createSelector([selectCategoryName], (categoryname) => categoryname.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectCategoryName], (categoryname) => categoryname.create);

export const selectUpdatedItem = createSelector([selectCategoryName], (categoryname) => categoryname.update);

export const selectReadItem = createSelector([selectCategoryName], (categoryname) => categoryname.read);

export const selectDeletedItem = createSelector([selectCategoryName], (categoryname) => categoryname.delete);

export const selectSearchedItems = createSelector([selectCategoryName], (categoryname) => categoryname.search);

export const selectItemsCount = createSelector([selectCategoryName], (categoryname) => categoryname.count);

export const selectItemsFilter = createSelector([selectCategoryName], (categoryname) => categoryname.filter);



