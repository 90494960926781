import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IssueHeader from "../../components/IssueHeader";
import IssuesHome from "../../components/IssuesHome";
import EmptyBoard from './EmptyBoard';
import { selectListItems } from '../../redux/issuecards/selectors.js';
import { issuecards } from "../../redux/issuecards/actions";
import { selectCurrent } from '../../redux/menu/selectors';
export default function IssueManagement() {
	const { result } = useSelector(selectListItems);
	const { tenantReload } = useSelector(selectCurrent);
	const { items } = result
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(issuecards.list({ entity: 'PDI_Issue_Management', options: { page: 1, limit: 5 } }))
	}, [])

	return (
		<>
			{items.length > 0 ?
				<>
					<IssueHeader />
					<IssuesHome
						items={items}
					/>
				</>
				:
				<>
					<EmptyBoard type='add' />
				</>
			}

		</>)
}
