import { createSelector } from 'reselect';

const selectIssueCards = (state) => state.issuecards;

export const selectCurrentItem = createSelector([selectIssueCards], (issuecards) => issuecards.current);

export const selectListItems = createSelector([selectIssueCards], (issuecards) => issuecards.list);

export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectIssueCards], (issuecards) => issuecards.create);

export const selectUpdatedItem = createSelector([selectIssueCards], (issuecards) => issuecards.update);

export const selectReadItem = createSelector([selectIssueCards], (issuecards) => issuecards.read);

export const selectDeletedItem = createSelector([selectIssueCards], (issuecards) => issuecards.delete);

export const selectSearchedItems = createSelector([selectIssueCards], (issuecards) => issuecards.search);

export const selectItemsCount = createSelector([selectIssueCards], (issuecards) => issuecards.count);

export const selectItemsFilter = createSelector([selectIssueCards], (issuecards) => issuecards.filter);