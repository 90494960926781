import { createSelector } from 'reselect';

const selectInspectionStatus = (state) => state.inspectionstatus;

export const selectCurrentItem = createSelector([selectInspectionStatus], (inspectionstatus) => inspectionstatus.current);

export const selectListItems = createSelector([selectInspectionStatus], (inspectionstatus) => inspectionstatus.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectInspectionStatus], (inspectionstatus) => inspectionstatus.create);

export const selectUpdatedItem = createSelector([selectInspectionStatus], (inspectionstatus) => inspectionstatus.update);

export const selectReadItem = createSelector([selectInspectionStatus], (inspectionstatus) => inspectionstatus.read);

export const selectDeletedItem = createSelector([selectInspectionStatus], (inspectionstatus) => inspectionstatus.delete);

export const selectSearchedItems = createSelector([selectInspectionStatus], (inspectionstatus) => inspectionstatus.search);

export const selectItemsCount = createSelector([selectInspectionStatus], (inspectionstatus) => inspectionstatus.count);

export const selectItemsFilter = createSelector([selectInspectionStatus], (inspectionstatus) => inspectionstatus.filter);



