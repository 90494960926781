import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyBoard from './EmptyBoard';
import InspectionHome from './InspectionHome';
import { selectListItems } from '../../redux/inspectioncards/selectors.js';
import { inspectioncards } from "../../redux/inspectioncards/actions";
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { crm } from '../../redux/crm/actions'
import AddEditIssueItem from "./../../modals/AddEditIssueItem"

export default function InspectionBoard() {
	const dispatch = useDispatch()
	const { result } = useSelector(selectListItems);
	const { boardType, projectChecklistOpen, activeBoardName, inspectionReload, inspectionModalOpen } = useSelector(selectCurrentCRM)

	const { items } = result

	const columnName =
	{
		"IssuePriority": {},
		"Attachment": {},
		"IssueTitle": {},
		"Description": {},
		"IssueStatus": {},
		"InspectionCard": {},
		"CreatedDate": {},
	}

	useEffect(() => {
		if (inspectionReload) {
			dispatch(inspectioncards.list({ entity: 'PDI_Inspection_Items', options: { page: 1, limit: 5 } }))
			dispatch(crm.trigger({ inspectionReload: false }))
		}
	}, [inspectionReload])

	return (
		<>
			{inspectionModalOpen === 'open' ? <AddEditIssueItem items={items} tableName="PDI_Issue_Management" columnName={columnName} /> : ""
			}
			{items.length > 0 ?
				<>
					<InspectionHome items={items} />
				</>
				:
				<>
					<EmptyBoard type='add' />
				</>
			}

		</>)
}
