import { createSelector } from 'reselect';

const selectIssuePriority = (state) => state.issuepriority;

export const selectCurrentItem = createSelector([selectIssuePriority], (issuepriority) => issuepriority.current);

export const selectListItems = createSelector([selectIssuePriority], (issuepriority) => issuepriority.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectIssuePriority], (issuepriority) => issuepriority.create);

export const selectUpdatedItem = createSelector([selectIssuePriority], (issuepriority) => issuepriority.update);

export const selectReadItem = createSelector([selectIssuePriority], (issuepriority) => issuepriority.read);

export const selectDeletedItem = createSelector([selectIssuePriority], (issuepriority) => issuepriority.delete);

export const selectSearchedItems = createSelector([selectIssuePriority], (issuepriority) => issuepriority.search);

export const selectItemsCount = createSelector([selectIssuePriority], (issuepriority) => issuepriority.count);

export const selectItemsFilter = createSelector([selectIssuePriority], (issuepriority) => issuepriority.filter);



