import { menu } from "../redux/menu/actions";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { crud } from "../redux/crud/actions";
import { crm } from "../redux/crm/actions";
import { selectListItems } from '../redux/categoryname/selectors';
import { categoryname } from "../redux/categoryname/actions";
import _ from 'lodash'

export default function AddEditInspectionChecklist({ items, tableName, columnName }) {
	const dispatch = useDispatch();
	const { checklistModalOpen, isChecklistEdit, checklistItem, activeChecklistId, checklistMasterReload } = useSelector(selectCurrentCRM)
	const [steps, setSteps] = useState({});
	const [steps1, setSteps1] = useState(checklistItem);
	const { result } = useSelector(selectListItems)
	const { items: _items } = result

	const displayFormat = (data) => {
		return _.startCase(data)
	}

	const handleClose = (data) => {
		dispatch(crm.trigger({ checklistModalOpen: 'close' }))
	}

	const handleSave = () => {
		if (isChecklistEdit) {
			dispatch(crud.update({ entity: tableName, id: activeChecklistId, jsonData: { entity: steps1 } })).then(() => {
				dispatch(crm.trigger({ checklistModalOpen: 'close', checklistReload: true }))
			})
		}
		else {
			dispatch(crud.create({ entity: tableName, jsonData: { entity: steps } })).then(() => {
				dispatch(crm.trigger({ checklistModalOpen: 'close', checklistReload: true }))
			})
		}
	}

	const handleSteps1 = (e, key) => {
		setSteps1({ ...steps1, [key]: e.target.value })
	}

	const handleSteps = (e, key) => {
		setSteps({ ...steps, [key]: e.target.value })
	}

	useEffect(() => {
		dispatch(categoryname.list({ entity: 'PDI_Category_Name', options: {} }))
	}, [])

	const [device, setDevice] = useState('mobile')
	return (
		<>
			{checklistModalOpen == 'open' ? (
				<div
					className={
						device === "mobile"
							? "  py-6 px-6 pb-40  absolute  left-0 flex  right-0  top-0 dropdown "
							: "  py-6 px-6 pb-40  absolut   left-0 flex  right-0 bottom-0 top-0 dropdown "
					}
					onClick={(e) => {
						if (e.target !== e.currentTarget) {
							return;
						}
					}}
				>
					<div
						className="bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold shadow-md shadow-[#364e7e1a] max-w-md mx-auto  w-full px-8  py-8 rounded-xl">
						<h3 className="text-lg border-b-2 border-white pb-3">
							{!isChecklistEdit ? "Add " + tableName : "Edit " + tableName}
						</h3>
						{isChecklistEdit ? <div className="w-full lg:w-12/12 px-4 mt-6 overflow-auto max-h-[65vh]">
							<div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password" >
									{displayFormat('categoryName')}
								</label>
								<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full py-1"
										onChange={(e) => handleSteps1(e, 'categoryName')}
										defaultValue={steps1.categoryName._id}>
										{_items.map((item, index) => {
											return <option value={item._id} key={index}> {item.Name}</option>
										})}
									</select>
								</div>
							</div>
							<div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password"
								>
									{displayFormat("description")}
								</label>
								<input
									type="text"
									name={"step_2"}
									className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps1(e, "description")}
									value={steps1["description"]}
								/>
							</div>
							<div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password" >
									{displayFormat("additionalInfo")}
								</label>
								<input
									type="text"
									name={"step_3"}
									className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps1(e, "additionalInfo")}
									value={steps1["additionalInfo"]}
								/>
							</div>
						</div> : <div className="w-full lg:w-12/12 px-4 mt-6 overflow-auto max-h-[65vh]">
							<div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password"
								>
									{displayFormat('categoryName')}
								</label>
								<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full py-1"
										onChange={(e) => handleSteps(e, 'categoryName')}
										value={steps['categoryName']}>
										{_items.map((item, index) => {
											return <option value={item._id} key={index}> {item.Name}</option>
										})}
									</select>
								</div>
							</div>
							<div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password"
								>
									{displayFormat("description")}
								</label>
								<input
									type="text"
									name={"step_2"}
									className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps(e, "description")}
									value={steps["description"]}
								/>
							</div>
							<div className="relative w-full mb-3">
								<label
									className="block uppercase text-xs font-bold mb-2"
									htmlFor="grid-password"
								>
									{displayFormat("additionalInfo")}
								</label>
								<input
									type="text"
									name={"step_3"}
									className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(e) => handleSteps(e, "additionalInfo")}
									value={steps["additionalInfo"]}
								/>
							</div>
						</div>}
						<div className="mt-8 flex flex-row justify-between border-t-2 border-white pt-3 mx-4 ">
							<button
								className="w-36 items-center dark:text-white dark:bg-blue-600   text-white bg-[#635fc7] py-1 px-1 rounded-xl"
								onClick={() => {
									handleClose()
								}}
							> Close</button>
							<button
								className="w-36 items-center dark:text-white dark:bg-blue-600  text-white bg-[#635fc7] py-1 px-1  rounded-xl"
								onClick={() => handleSave()}>
								{!isChecklistEdit ? "New" : "Update"} Record
							</button>
						</div>
					</div>
				</div >
			) : null
			}
		</>
	);
}

