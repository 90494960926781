import { menu } from "../redux/menu/actions";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { selectListItems as selectListInspectonItems } from '../redux/inspectioncards/selectors';
import { issuecards } from "../redux/issuecards/actions";
import { crm } from "../redux/crm/actions";
import _ from 'lodash'
import { issuestatus } from "../redux/issuestatus/actions";
import { issuepriority } from "../redux/issuepriority/actions";
import { selectListItems } from "../redux/issuestatus/selectors";
import { selectListItems as selectPriorityList } from "../redux/issuepriority/selectors";

import { inspectioncards } from "../redux/inspectioncards/actions";

export default function AddEditIssueItem({ items, tableName, columnName }) {
	const dispatch = useDispatch();
	const propertyNames = Object.keys(columnName);
	const { issueItem, isIssueEdit, issueModalOpen, activeIssueId } = useSelector(selectCurrentCRM);
	const { result } = useSelector(selectListItems)
	const { result: _result } = useSelector(selectListInspectonItems)
	const { result: priority } = useSelector(selectPriorityList)
	const { items: _items } = result
	const { items: __items } = _result
	const { items: priorityStaus } = priority
	const [steps, setSteps] = useState({});
	const [steps1, setSteps1] = useState(issueItem);
	const [device, setDevice] = useState('mobile')

	const displayFormat = (data) => {
		return _.startCase(data)
	}

	const handleClose = (data) => {
		dispatch(crm.trigger({ issueModalOpen: 'close' }))
	}

	const handleSave = () => {
		if (isIssueEdit) {
			dispatch(issuecards.update({ entity: 'PDI_Issue_Management', id: activeIssueId, jsonData: { entity: steps1 } })).then(() => {
				dispatch(crm.trigger({ issueModalOpen: 'close', issueReload: true }))
			})
		}
		else {
			dispatch(issuecards.create({ entity: 'PDI_Issue_Management', jsonData: { entity: steps } })).then(() => {
				dispatch(crm.trigger({ issueModalOpen: 'close', issueReload: true }))
			})
		}
	}

	const handleSteps1 = (e, key) => {
		setSteps1({ ...steps1, [key]: e.target.value })
	}

	const handleSteps = (e, key) => {
		setSteps({ ...steps, [key]: e.target.value })
	}

	useEffect(() => {
		dispatch(issuepriority.list({ entity: 'PDI_Issue_Priority', options: {} }))
		dispatch(issuestatus.list({ entity: 'PDI_Issue_Status', options: {} }))
		dispatch(inspectioncards.list({ entity: 'PDI_Inspection_Items', options: {} }))
	}, [])

	return (
		<>
			{issueModalOpen == 'open' ? (
				<div className="absolute my-5 left-0   right-0  top-0  ">
					<div
						className="w-[1000px] bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold shadow-md shadow-[#364e7e1a] max-w-md mx-auto  w-full px-8  py-8 rounded-xl">
						<h3 className="text-lg border-b-2 border-white pb-3">
							{!isIssueEdit ? "Add " + tableName : "Edit " + tableName}
						</h3>

						{!isIssueEdit ?
							<div className="w-full lg:w-12/12 px-4 mt-6 overflow-auto max-h-[65vh]">
								<div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password"
									>
										{displayFormat(propertyNames[2])}
									</label>
									<input
										type="text"
										name={"step_2"}
										className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										onChange={(e) => handleSteps(e, propertyNames[2])}
										value={steps[propertyNames[2]]}
									/>
								</div>
								<div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password">
										{displayFormat(propertyNames[3])}
									</label>
									<textarea
										type="text"
										name={"step_3"}
										className="resize rounded-md border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										onChange={(e) => handleSteps(e, propertyNames[3])}
										value={steps[propertyNames[3]]}>
									</textarea>
								</div>
								<div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password"
									>
										{displayFormat(propertyNames[5])}
									</label>
									<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
										<select
											className="w-full py-1" onChange={(e) => handleSteps(e, "InspectionCard")}
											defaultValue={'65967b6013fd1e001ae7e300'}
										>
											{__items.map((item, index) => {
												return <option value={item._id} key={index}> {item.appartmentNumber}</option>
											})}
										</select>
									</div>
								</div>
								<div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password"
									>
										{displayFormat(propertyNames[0])}
									</label>
									<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
										<select
											className="w-full py-1" onChange={(e) => handleSteps(e, "IssuePriority")}
											defaultValue={'659cdc9dcbe502001aafb6f5'}
										>
											{priorityStaus.map((item, index) => {
												return <option value={item._id} key={index}> {item.Name}</option>
											})}
										</select>
									</div>
								</div>
								<div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password"
									>
										{displayFormat(propertyNames[4])}
									</label>
									<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
										<select
											className="w-full py-1" onChange={(e) => handleSteps(e, "IssueStatus")}
											defaultValue={'659cdc9dcbe502001aafb6f5'}
										>
											{_items.map((item, index) => {
												return <option value={item._id} key={index}> {item.Name}</option>
											})}
										</select>
									</div>
								</div>
								<div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password"
									>
										{displayFormat(propertyNames[6])}
									</label>
									<input
										type="date"
										name={"step_4"}
										className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										onChange={(e) => handleSteps(e, propertyNames[6])}
										value={steps[6]}
									/>
								</div>
								<div className="relative w-full mb-3">
									<label
										htmlFor="formFile"
										className="block uppercase text-xs font-bold mb-2">
										Image URL :

										{steps[propertyNames[1]]}
									</label>

									<input
										className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
										type="file"
										id="formFile" />
								</div>
							</div> : <div className="w-full lg:w-12/12 px-4 mt-6 overflow-auto max-h-[65vh]">
								<div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password"
									>
										{displayFormat(propertyNames[2])}
									</label>
									<input
										type="text"
										name={"step_2"}
										className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										onChange={(e) => handleSteps1(e, propertyNames[2])}
										value={steps1[propertyNames[2]]}
									/>
								</div>
								<div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password"
									>
										{displayFormat(propertyNames[3])}
									</label>
									<textarea
										type="text"
										name={"step_3"}
										className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										onChange={(e) => handleSteps1(e, propertyNames[3])}
										value={steps1[propertyNames[3]]}
									/>
								</div>
								<div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password"
									>
										{displayFormat(propertyNames[5])}
									</label>
									<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
										<select
											className="w-full py-1" onChange={(e) => handleSteps1(e, propertyNames[5])}
											defaultValue={steps1.InspectionCard._id}
										>
											{__items.map((item, index) => {
												return <option value={item._id} key={index}> {item.appartmentNumber}</option>
											})}
										</select>
									</div>
								</div>

								<div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password" >
										{displayFormat(propertyNames[0])}
									</label>
									<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
										<select
											className="w-full py-1" onChange={(e) => handleSteps1(e, "IssuePriority")}
											defaultValue={'659cdc9dcbe502001aafb6f5'} >
											{priorityStaus.map((item, index) => {
												return <option value={item._id} key={index}> {item.Name}</option>
											})}
										</select>
									</div>
								</div>
								<div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password"
									>
										{displayFormat(propertyNames[4])}
									</label>
									<div className="border-0 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
										<select
											className="w-full py-1" onChange={(e) => handleSteps1(e, propertyNames[4])}
											defaultValue={steps1.IssueStatus._id}
										>
											{_items.map((item, index) => {
												return <option value={item._id} key={index}> {item.Name}</option>
											})}
										</select>
									</div>
								</div>
								<div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password"
									>
										{displayFormat(propertyNames[6])}
									</label>
									<input
										type="date"
										name={"step_4"}
										className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										onChange={(e) => handleSteps1(e, propertyNames[6])}
										value={steps1[6]}
									/>
								</div>
								<div className="relative w-full mb-3">
									<label
										htmlFor="formFile"
										className="block uppercase text-xs font-bold mb-2">
										Image URL : {steps1[propertyNames[1]]}
									</label>
									<input
										className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
										type="file"
										id="formFile" />
								</div>
							</div>
						}
						<div className="mt-8 flex flex-row justify-between border-t-2 border-white pt-3 mx-4 ">
							<button
								className="w-36 items-center dark:text-white dark:bg-blue-600   text-white bg-[#635fc7] py-1 px-1 rounded-xl"
								onClick={() => {
									handleClose()
								}}
							> Close</button>
							<button
								className="w-36 items-center dark:text-white dark:bg-blue-600  text-white bg-[#635fc7] py-1 px-1  rounded-xl"
								onClick={() => handleSave()}>
								{!isIssueEdit ? "New" : "Update"} Record
							</button>
						</div>
					</div>
				</div >
			) : null
			}
		</>
	);
}

