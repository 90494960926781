// import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
// import { createLogger } from 'redux-logger/';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import storePersist from './storePersist';

// const logger = createLogger();
// let middleware = [thunk];

// let configStore = applyMiddleware(...middleware);

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// if (process.env.NODE_ENV === 'development') {
// middleware = [...middleware];
// configStore = composeEnhancers(applyMiddleware(...middleware));
// }

const initialState = storePersist.get('auth') ? { auth: storePersist.get('auth') } : {};

// const store = createStore(rootReducer, initialState, configStore);
export const store = configureStore({ reducer: rootReducer });

export default store;
