import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddEditBoardModal from "../modals/AddEditBoardModal";
import EmptyBoard from "../pages/inspection-crm/EmptyBoard";
import { selectCurrentCRM } from '../redux/crm/selectors';
import { shuffle } from "lodash";
import { selectListItems } from '../redux/issuestatus/selectors';
import { issuestatus } from '../redux/issuestatus/actions'

function IssuesHome({ items }) {
	const colors = [
		"bg-red-500",
		"bg-orange-500",
		"bg-blue-500",
		"bg-purple-500",
		"bg-green-500",
		"bg-indigo-500",
		"bg-yellow-500",
		"bg-pink-500",
		"bg-sky-500",
	];

	const dispatch = useDispatch();
	const { result: issuesResult } = useSelector(selectListItems)
	const { items: status } = issuesResult
	const [color, setColor] = useState(null)
	const { boardType } = useSelector(selectCurrentCRM)
	const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
	const columns = status;
	const [isSideBarOpen, setIsSideBarOpen] = useState(true);
	const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

	const handleOnDrop = (e) => {
		// const { prevColIndex, taskIndex } = JSON.parse(
		// 	e.dataTransfer.getData("text")
		// );

		// if (colIndex !== prevColIndex) {
		// 	dispatch(
		// 		boardsSlice.actions.dragTask({ colIndex, prevColIndex, taskIndex })
		// 	);
		// }
	};

	const handleOnDragOver = (e) => {
		e.preventDefault();
	};

	const handleOnDrag = (e) => {
		// e.dataTransfer.setData(
		// 	"text",
		// 	JSON.stringify({ taskIndex, prevColIndex: colIndex })
		// );
	};

	useEffect(() => {
		dispatch(issuestatus.list({ entity: 'PDI_Issue_Status', options: {} }))
	}, [])

	useEffect(() => {
		setColor(shuffle(colors).pop())
	}, [dispatch]);

	return (
		<div
			className={isSideBarOpen
				? " bg-[#f4f7fd]   flex dark:bg-[#20212c] h-screen overflow-scroll "
				: "bg-[#f4f7fd]   flex dark:bg-[#20212c]  h-screen  overflow-scroll "}
		>
			{columns.length > 0 ? (
				<>
					{columns.map((col, index) => (
						<div
							onDrop={handleOnDrop}
							onDragOver={handleOnDragOver}
							className="scrollbar-hide   mx-5 pt-[20px] min-w-[280px]">
							<p className=" font-semibold flex  items-center  gap-2 tracking-widest md:tracking-[.2em] text-[#828fa3]">
								<div className={`rounded-full w-4 h-4 ${color} `} />
								{col.Name}
							</p>
							{items.map((item, index) => {
								if (item.IssueStatus.Name == col.Name) {
									return <div
										onClick={() => {
											setIsTaskModalOpen(true);
										}}
										draggable
										onDragStart={handleOnDrag}
										className=" w-[280px] first:my-5 rounded-lg  bg-white  dark:bg-[#2b2c37] shadow-[#364e7e1a] py-6 px-3 shadow-lg hover:text-[#635fc7] dark:text-white dark:hover:text-[#635fc7] cursor-pointer my-3 "
									>
										<p className=" font-bold tracking-wide ">{item.IssueTitle}</p>
										<p className=" font-bold text-xs tracking-tighter mt-2 text-gray-500">
											{item.Description}
										</p>
									</div>
								}
							})}
						</div>
					))}
				</>
			) : (
				<>
					<EmptyBoard type="edit" />
				</>
			)}
			{isBoardModalOpen && (
				<AddEditBoardModal
					type="edit"
					setIsBoardModalOpen={setIsBoardModalOpen}
				/>
			)}
		</div>
	);
}

export default IssuesHome;
