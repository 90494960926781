import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appartment1 from "./../../assets/img/appartment1.png"
import appartment2 from "./../../assets/img/appartment2.png"
import appartment3 from "./../../assets/img/appartment3.png"
import appartment4 from "./../../assets/img/appartment4.png"
import { selectDocumentItems } from '../../redux/documents/selectors';
import { document } from "../../redux/documents/actions"

export default function DocumentS3() {
	const dispatch = useDispatch()
	const { result } = useSelector(selectDocumentItems);
	const { items } = result;
	console.log(items)

	const imageUrl = (idx) => {
		if (idx == 0) {
			return appartment1
		}
		else if (idx == 1) {
			return appartment2
		}
		else if (idx == 2) {
			return appartment3
		}
		else if (idx == 3) {
			return appartment4
		}
	}

	const handleExcelImport = () => {
		dispatch(document.importexcel({
			entity: 'document', jsonData: {
				"collectionName": "pdi_checklist_items",
				"key": "c8f9edeb-c7a3-4963-8bf0-eecd42325ee8.xlsx",
			},
			options: {}
		}))
	}
	//useEffect(() => {
	// dispatch(document.downloadurl({
	// 	entity: 'document', id: "3cb5750a-313c-4087-873b-6a63c60936f9.jpeg",
	// 	options: {}
	// }))

	//}, [])
	return (
		<div className="flex flex-col" >
			<div className="flex flex-row w-1/2 ml-5 justify-center my-5">
				<div className="flex flex-row h-48 ">
					<img
						className=" h-48 w-48 rounded-bl-md rounded-tr-none rounded-tr-md rounded-tl-md"
						src={imageUrl(1)}
						alt="image-text"
						onClick={() => handleExcelImport()}
					/>
				</div>
			</div>
		</div >
	);
}
