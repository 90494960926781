import storePersist from '../storePersist';
import * as actionTypes from './types';

const INITIAL_STATE = {
	current: {},
	loading: false,
	isLoggedIn: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.AUTH_LOADING_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actionTypes.AUTH_FAILED_REQUEST:
			return INITIAL_STATE;

		case actionTypes.AUTH_LOGIN_SUCCESS:
			storePersist.set('auth', {
				current: action.payload,
				loading: false,
				isLoggedIn: true,
			})
			return {
				current: action.payload,
				loading: false,
				isLoggedIn: true,
			};
		case actionTypes.AUTH_LOGOUT_SUCCESS:
			return INITIAL_STATE;

		default:
			return state;
	}
};

export default authReducer;
