import { useCallback, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { menu } from "../redux/menu/actions";
import { useDispatch, useSelector } from 'react-redux';


const Button2 = ({ content, onClick, active, disabled }) => {
	return (
		<button
			className={`flex flex-col cursor-pointer items-center justify-center w-full h-6 m-2 px-1 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-lg font-normal transition-colors rounded-lg 
		${active ? "dark:bg-indigo-500 dark:text-white" : "text-indigo-500"}
		${!disabled
					? "bg-white hover:bg-indigo-500 hover:text-white"
					: "text-indigo-300 bg-white cursor-not-allowed"
				}
		`}
			onClick={onClick}
			disabled={disabled}
		>
			{content}
		</button>
	);
}

const Pagination = ({
	gotoPage,
	canPreviousPage,
	canNextPage,
	pageCount,
	pageIndex,
}) => {
	const dispatch = useDispatch();
	const handlePage = (pageIndexToMap) => {
		dispatch(menu.trigger({ page: pageIndexToMap + 1 }))
	}
	const renderPageLinks = useCallback(() => {

		if (pageCount === 0) return null;
		const visiblePageButtonCount = 5;
		let numberOfButtons =
			pageCount < visiblePageButtonCount ? pageCount : visiblePageButtonCount;
		const pageIndices = [pageIndex];
		numberOfButtons--;
		[...Array(numberOfButtons)].forEach((_item, itemIndex) => {
			const pageNumberBefore = pageIndices[0] - 1;
			const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;
			if (
				pageNumberBefore >= 0 &&
				(itemIndex < numberOfButtons / 2 || pageNumberAfter > pageCount - 1)
			) {
				pageIndices.unshift(pageNumberBefore);
			} else {
				pageIndices.push(pageNumberAfter);
			}
		});
		return pageIndices.map((pageIndexToMap) => (
			<li key={pageIndexToMap} className="ml-2 mr-2 text-indigo-500">
				<Button2
					content={pageIndexToMap + 1}
					onClick={() => handlePage(pageIndexToMap)}
					active={pageIndex === pageIndexToMap}
				/>
			</li>
		));
	}, [pageCount, pageIndex]);
	return (
		<ul className="flex gap-2 justify-center bg-slate-200 dark:bg-[#20212c] h-12">
			<li className="ml-2 mr-2 ">
				<Button2
					content={
						<div className="w-full h-auto mr-1  px-2 py-1 ">
							<ChevronLeftIcon size="0.6rem" />
						</div>
					}
					onClick={() => gotoPage(0)}
					disabled={!canPreviousPage}
				/>
			</li>
			{renderPageLinks()}
			<li className="ml-2 mr-2 ">
				<Button2
					content={
						<div className="w-full h-auto ml-1  px-2 py-1">
							<ChevronRightIcon size="0.6rem" />
						</div>
					}
					onClick={() => gotoPage(pageCount - 1)}
					disabled={!canNextPage}
				/>
			</li>
		</ul>
	);
}

export default Pagination;