import { combineReducers } from 'redux';
import { reducer as authReducer } from './auth';
import { reducer as crudReducer } from './crud';
import { reducer as notifyReducer } from './notify';
import * as actionTypes from './auth/types';
import menuReducer from './menu/reducer';
import crmReducer from './crm/reducer';
import projectcardsReducer from './projectcards/reducer';
import inspectioncardsReducer from './inspectioncards/reducer';
import inspectionstatusReducer from './inspectionstatus/reducer';
import issuestatusReducer from './issuestatus/reducer';
import issuecardsReducer from './issuecards/reducer';
import inspectionchecklistReducer from './inspectionchecklist/reducer';
import categorynameReducer from './categoryname/reducer';
import issuepriorityReducer from './issuepriority/reducer';
import projecttypeReducer from './projecttype/reducer';
import documentReducer from './documents/reducer';
import usersReducer from './users/reducer';



const appReducer = combineReducers({
	auth: authReducer,
	notify: notifyReducer,
	crud: crudReducer,
	menu: menuReducer,
	crm: crmReducer,
	document: documentReducer,
	issuepriority: issuepriorityReducer,
	projecttype: projecttypeReducer,
	inspectionstatus: inspectionstatusReducer,
	projectcards: projectcardsReducer,
	inspectioncards: inspectioncardsReducer,
	issuestatus: issuestatusReducer,
	categoryname: categorynameReducer,
	issuecards: issuecardsReducer,
	users: usersReducer,
	inspectionchecklist: inspectionchecklistReducer
});

const rootReducer = (state, action) => {
	if (action.type === actionTypes.AUTH_LOGOUT_SUCCESS) {
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
