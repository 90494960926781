import * as actionTypes from './types';
import { request } from '../../request';

export const document = {

	resetState:
		(props = {}) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_RESET_STATE,
				});
			},
	resetAction:
		({ actionType }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_RESET_ACTION,
					keyState: actionType,
					payload: null,
				});
			},
	currentItem:
		({ data }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_CURRENT_ITEM,
					payload: { ...data },
				});
			},
	currentAction:
		({ actionType, data }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_CURRENT_ACTION,
					keyState: actionType,
					payload: { ...data },
				});
			},
	list:
		({ entity, options = { page: 1 } }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_REQUEST_LOADING,
					keyState: 'list',
					payload: null,
				});

				let data = await request.list({ entity, options });

				if (data.success === true) {
					const result = {
						items: data.result,
					};
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_SUCCESS,
						keyState: 'list',
						payload: result,
					});
				} else {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_FAILED,
						keyState: 'list',
						payload: null,
					});
				}
			},
	filter:
		({ entity, options = { page: 1 } }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_REQUEST_LOADING,
					keyState: 'filter',
					payload: null,
				});

				let data = await request.filter({ entity, options });

				if (data.success === true) {
					const result = {
						items: data.result,
						pagination: {
							current: parseInt(data.pagination.page, 10),
							pageSize: 10,
							showSizeChanger: false,
							total: parseInt(data.pagination.count, 10),
						},
					};
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_SUCCESS,
						keyState: 'filter',
						payload: result,
					});
				} else {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_FAILED,
						keyState: 'filter',
						payload: null,
					});
				}
			},
	count:
		({ entity, options = { page: 1 } }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_REQUEST_LOADING,
					keyState: 'count',
					payload: null,
				});

				let data = await request.count({ entity, options });

				if (data.success === true) {
					const result = {
						items: data.result,
						pagination: {
							current: parseInt(data.pagination.page, 10),
							pageSize: 10,
							showSizeChanger: false,
							total: parseInt(data.pagination.count, 10),
						},
					};
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_SUCCESS,
						keyState: 'count',
						payload: result,
					});
				} else {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_FAILED,
						keyState: 'list',
						payload: null,
					});
				}
			},
	create:
		({ entity, jsonData }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_REQUEST_LOADING,
					keyState: 'create',
					payload: null,
				});

				let data = await request.create({ entity, jsonData });

				if (data.success === true) {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_SUCCESS,
						keyState: 'create',
						payload: data.result,
					});

					dispatch({
						type: actionTypes.DOCUMENT_CURRENT_ITEM,
						payload: data.result,
					});
				} else {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_FAILED,
						keyState: 'create',
						payload: null,
					});
				}
			},
	importexcel:
		({ entity, jsonData }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_REQUEST_LOADING,
					keyState: 'importexcel',
					payload: null,
				});

				let data = await request.importexcel({ entity, jsonData });

				if (data.success === true) {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_SUCCESS,
						keyState: 'importexcel',
						payload: data.result,
					});

					dispatch({
						type: actionTypes.DOCUMENT_CURRENT_ITEM,
						payload: data.result,
					});
				} else {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_FAILED,
						keyState: 'importexcel',
						payload: null,
					});
				}
			},
	read:
		({ entity, id }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_REQUEST_LOADING,
					keyState: 'read',
					payload: null,
				});

				let data = await request.read({ entity, id });

				if (data.success === true) {
					const result = {
						metaData: data.result?.metaData
					};
					dispatch({
						type: actionTypes.DOCUMENT_CURRENT_ITEM,
						payload: result,
					});
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_SUCCESS,
						keyState: 'read',
						payload: result,
					});
				} else {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_FAILED,
						keyState: 'read',
						payload: null,
					});
				}
			},
	downloadurl:
		({ entity, id }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_REQUEST_LOADING,
					keyState: 'downloadurl',
					payload: null,
				});

				let data = await request.downloadurl({ entity, id });

				if (data.success === true) {
					const result = {
						metaData: data.result?.metaData
					};
					dispatch({
						type: actionTypes.DOCUMENT_CURRENT_ITEM,
						payload: result,
					});
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_SUCCESS,
						keyState: 'read',
						payload: result,
					});
				} else {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_FAILED,
						keyState: 'read',
						payload: null,
					});
				}
			},
	update:
		({ entity, id, jsonData }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_REQUEST_LOADING,
					keyState: 'update',
					payload: null,
				});

				let data = await request.update({ entity, id, jsonData });

				if (data.success === true) {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_SUCCESS,
						keyState: 'update',
						payload: data.result,
					});
					dispatch({
						type: actionTypes.DOCUMENT_CURRENT_ITEM,
						payload: data.result,
					});
				} else {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_FAILED,
						keyState: 'update',
						payload: null,
					});
				}
			},
	rebuild:
		({ entity, jsonData }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_REQUEST_LOADING,
					keyState: 'rebuild',
					payload: null,
				});

				let data = await request.rebuild({ entity, jsonData });

				if (data.success === true) {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_SUCCESS,
						keyState: 'columns',
						payload: data.result,
					});
				} else {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_FAILED,
						keyState: 'columns',
						payload: null,
					});
				}
			},
	delete:
		({ entity, id }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_REQUEST_LOADING,
					keyState: 'delete',
					payload: null,
				});

				let data = await request.delete({ entity, id });

				if (data.success === true) {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_SUCCESS,
						keyState: 'delete',
						payload: data.result,
					});
				} else {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_FAILED,
						keyState: 'delete',
						payload: null,
					});
				}
			},

	search:
		({ entity, options = {} }) =>
			async (dispatch) => {
				dispatch({
					type: actionTypes.DOCUMENT_REQUEST_LOADING,
					keyState: 'search',
					payload: null,
				});

				let data = await request.search({ entity, options });

				if (data.success === true) {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_SUCCESS,
						keyState: 'search',
						payload: data.result,
					});
				} else {
					dispatch({
						type: actionTypes.DOCUMENT_REQUEST_FAILED,
						keyState: 'search',
						payload: null,
					});
				}
			},
};
