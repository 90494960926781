import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddDataTableModal from "../../components/Modal/AddDataTable/AddEditInspectionItem"
import { selectListItems } from '../../redux/users/selectors';
import { selectListItems as selectProjectList } from '../../redux/projectcards/selectors';
import { selectCurrent } from '../../redux/menu/selectors';
import { users } from "../../redux/users/actions";
import TableDropdown from "../../modals/TableDropdown.js";
import Pagination from "../../components/Pagination";
import Confirmation from "../../modals/Confirmation";
import { projectcards } from '../../redux/projectcards/actions';
import { useNavigate } from 'react-router-dom';

export default function UserMangement() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [search, setSearch] = useState('')
	const { tenantReload, addTenant } = useSelector(selectCurrent)
	const { tableName, columnName } = useSelector(selectCurrent)
	const { result } = useSelector(selectListItems);
	const { result: result1 } = useSelector(selectProjectList)
	const { items, pagination } = result;
	const { items: items1 } = result1;

	const { current, pageSize } = pagination;
	const pageCount = pageSize;
	useEffect(() => {
		dispatch(users.list({ entity: "users", options: { page: 1, limit: 5 } }))
		dispatch(projectcards.list({ entity: "PDI_Project_Cards", options: {} }))
	}, [])


	const hanldeAddUser = () => {

	}

	const handleSearch = (emit) => {
	}
	return (
		<div className="flex flex-col item-center">
			<>
				<header className="flex justify-between h-24 w-full mx-auto px-5 dark:text-white items-center">
					<div className="flex flex-col justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-1 rounded-md bg-blue-gray-50 bg-opacity-60">
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<h3 className="text-xl font-bold">User Management</h3>
								</li>
							</ol>
						</nav>
						<div className="flex justify-start w-full py-2 mx-3" >
							<button onClick={() => navigate('/inspection-crm/user-management')} className="w-auto mx-1 button  bg-indigo-100 text-blue-600 px-3 rounded  text-xs font-bold"
							> Members
							</button>
							<button
								onClick={() => navigate('/inspection-crm/permissions')}
								className="w-auto button rounded bg-transparent text-black text-opacity-40 px-3 rounded text-xs font-bold"
							> Permission
							</button>
						</div>
					</div>
				</header >

				<Confirmation />
				<div className="flex flex-col  px-3 mx-5 h-[600px] shadow-lg rounded bg-white dark:bg-[#2b2c37]">
					<div className="flex flex-row items-center  justify-start w-full h-14 my-2  ">
						<form className="mx-3">
							<div className="relative flex w-full flex-wrap items-stretch">
								<span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
									<i className="fas fa-search"></i>
								</span>
								<input
									type="text"
									placeholder="Search here..."
									value={search}
									onChange={(e) => handleSearch(e)}
									className="border-x border-y px-3 py-2 border-black border-opacity-40 placeholder-blueGray-300 text-grey-600 relative rounded text-sm shadow outline-none focus:outline-none focus:ring w-[300px] pl-10"
								/>
							</div>
						</form>
						<div className="flex justify-end w-36 mx-auto space-x-3 mr-0" >
							<button className="w-auto px-3 button bg-blue-600 text-white rounded ring-1 ring-inset ring-blue-600 text-blue-600 p-2 text-xs font-bold"
							> + Add Member
							</button>
						</div>
					</div>
					<div className="min-h-[450px] w-full overflow-auto  ">
						<table className="items-center border-collapse w-full  ">
							<thead>
								<tr key={'table'}>
									<th key={1}
										className="w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
										User
									</th>
									<th key={2}
										className="w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
										Role
									</th>
									<th key={3}
										className="w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
										Email
									</th>
									<th key={4}
										className="w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
										Projects
									</th>
									<th key={5}
										className="w-auto mx-2 px-3  dark:text-white align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ">
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								{items.map((item, idx) => {
									return <tr key={idx}  >
										<td key={1}>
											<span className="flex flex-row space-x-2 w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
												<img
													className="h-10 w-10 -mt-3 rounded-full"
													src="https://manuarora.in/avatar.jpg"
													alt="avatar"
												/>
												<div>{item.firstName} {item.lastName}</div>
											</span>
										</td>
										<td key={2}>
											{idx == 0 ? <span className="w-auto mx-2 bg-indigo-100 dark:text-white  align-middle p-1 rounded  text-xs  border-l-0 border-r-0 whitespace-nowrap text-left" >
												Admin
											</span>
												:
												idx == 1 ? <span className="w-auto mx-2 bg-green-100 dark:text-white  align-middle p-1 rounded  text-xs  border-l-0 border-r-0 whitespace-nowrap text-left" >
													CRM Manager
												</span> :
													<span className="w-auto mx-2 bg-yellow-100 dark:text-white  align-middle p-1 rounded  text-xs  border-l-0 border-r-0 whitespace-nowrap text-left" >
														Site Engineer
													</span>}

										</td>
										<td key={3}>
											<span className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
												{item.email}
											</span>
										</td>
										<td key={3}>
											<span className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
												{items1.map((obj) => {
													return <div>{obj.ProjectName}, </div>
												})}
											</span>
										</td>
										<td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
											<TableDropdown activeId={item._id} item={item} />
										</td>
									</tr>
								})}
							</tbody>
						</table>
					</div >
					<div >
						<Pagination
							pageCount={pageCount}
							pageIndex={current - 1}
						/>
					</div>
				</div >
			</>
			{
				addTenant === 'open' ? <AddDataTableModal items={items} tableName={tableName} columnName={columnName} /> : ""
			}
		</div >
	);
}
