import * as actionTypes from './types';

export const menu = {
	trigger:
		(data) => async (dispatch) => {
			dispatch({
				type: actionTypes.MENU_ON_CHANGE,
				payload: data,
			});
		}
};