import React from 'react';
import { TailSpin } from "react-loader-spinner";


const PageLoader = () => {
	return (
		<>
			<TailSpin />
		</>


	);
};
export default PageLoader;
