
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Confirmation from "../../modals/Confirmation";


export default function Permissions() {
	const navigate = useNavigate()
	const [search, setSearch] = useState('')
	const handleSearch = (emit) => {
	}
	return <>
		<header className="flex justify-between h-24 w-full mx-auto px-5 dark:text-white items-center">
			<div className="flex flex-col justify-start w-full" >
				<nav aria-label="breadcrumb" className="w-max" >
					<ol className="flex flex-wrap items-center w-full px-4 py-1 rounded-md bg-blue-gray-50 bg-opacity-60">
						<li
							className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
							<h3 className="text-xl font-bold">User Management</h3>
						</li>
					</ol>
				</nav>
				<div className="flex justify-start w-full py-2 mx-3" >
					<button onClick={() => navigate('/inspection-crm/user-management')} className="w-auto mx-1 button  bg-transparent   text-black  text-opacity-40  px-3 rounded  text-xs font-bold"
					> Members
					</button>
					<button onClick={() => navigate('/inspection-crm/permissions')} className="w-auto button rounded  bg-indigo-100 text-blue-600   px-3 rounded text-xs font-bold"
					> Permission
					</button>
				</div>
			</div>
		</header>
		<Confirmation />
		<div className="flex flex-col  px-3 mx-5 h-[600px] shadow-lg rounded bg-white dark:bg-[#2b2c37]">
			<div className="flex flex-row items-center  justify-start w-full h-14 my-2  ">
				<form className="mx-3">
					<div className="relative flex w-full flex-wrap items-stretch">
						<span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
							<i className="fas fa-search"></i>
						</span>
						<input
							type="text"
							placeholder="Search here..."
							value={search}
							onChange={(e) => handleSearch(e)}
							className="border-x border-y px-3 py-2 border-black border-opacity-40 placeholder-blueGray-300 text-grey-600 relative rounded text-sm shadow outline-none focus:outline-none focus:ring w-[300px] pl-10"
						/>
					</div>
				</form>
			</div>
			<div className="grid grid-cols-2 gap-4 space-y-10 bg-indigo-200">
				<div className="h-48 w-1/2 text-xs bg-white">
					<div>Admin</div>
					<div className="felx flex-row space-y-1 ">
						<div className="flex flex-row space-x-10 border-b border-black border-opacity-40" >
							<div>Capability</div>
							<div>Permission</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Create new roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Edit existing roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>delete roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Assign roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Transfer Apps</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="h-48 w-1/2 text-xs bg-white">
					<div>CRM Manager</div>
					<div className="felx flex-row space-y-1">
						<div className="flex flex-row space-x-10 border-b border-black border-opacity-40" >
							<div>Capability</div>
							<div>Permission</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Create new roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Edit existing roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>delete roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Assign roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Transfer Apps</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="h-48 w-1/2 text-xs bg-white ">
					<div>CRM Executive</div>
					<div className="felx flex-row space-y-1">
						<div className="flex flex-row space-x-10 border-b border-black border-opacity-40" >
							<div>Capability</div>
							<div>Permission</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Create new roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Edit existing roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>delete roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Assign roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Transfer Apps</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="h-48 w-1/2 text-xs bg-white">
					<div>Site Engineer</div>
					<div className="felx flex-row space-y-1">
						<div className="flex flex-row space-x-10 border-b border-black border-opacity-40" >
							<div>Capability</div>
							<div>Permission</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Create new roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
					</div>
					<div className="felx flex-row space-y-1">
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Edit existing roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>delete roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Assign roles</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
						<div className="flex flex-row space-x-10 border-black border-opacity-40" >
							<div>Transfer Apps</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										className="px-1 py-1 w-10 text-center text-xs font-semibold rounded-l bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Yes</span>
									<span
										className="px-1 py-1 w-10 text-center  text-xs  font-semibold rounded-r bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">No</span>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}