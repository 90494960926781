import { menu } from "../../../redux/menu/actions";
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrent } from '../../../redux/menu/selectors';
import { crud } from "../../../redux/crud/actions";
import _ from 'lodash'

export default function AddEditInspectionItem({ items, tableName, columnName }) {
	const dispatch = useDispatch();
	const propertyNames = Object.keys(columnName);
	const { addTenant, isTenantEdit, activeCrudId, crudData } = useSelector(selectCurrent);
	const [steps, setSteps] = useState({});
	const [steps1, setSteps1] = useState(crudData);
	console.log('steps1', steps1)

	const displayFormat = (data) => {
		return _.startCase(data)
	}

	const handleClose = (data) => {
		dispatch(menu.trigger({ addTenant: 'close' }))
	}

	const handleSave = () => {
		if (!isTenantEdit) {
			dispatch(crud.update({ entity: tableName, id: activeCrudId, jsonData: { entity: steps1 } })).then(() => {
				dispatch(menu.trigger({ addTenant: 'close', tenantReload: true }))
			})
		}
		else {
			dispatch(crud.create({ entity: tableName, jsonData: { entity: steps } })).then(() => {
				dispatch(menu.trigger({ addTenant: 'close', tenantReload: true }))
			})
		}
	}

	const handleSteps1 = (e, key) => {
		setSteps1({ ...steps1, [key]: e.target.value })
	}

	const handleSteps = (e, key) => {
		setSteps({ ...steps, [key]: e.target.value })
	}

	const [device, setDevice] = useState('mobile')
	return (
		<>
			{addTenant == 'open' ? (
				<div
					className={
						device === "mobile"
							? "  py-6 px-6 pb-40  absolute  left-0 flex  right-0  top-0 dropdown "
							: "  py-6 px-6 pb-40  absolut   left-0 flex  right-0 bottom-0 top-0 dropdown "
					}
					onClick={(e) => {
						if (e.target !== e.currentTarget) {
							return;
						}
					}}
				>
					<div
						className="bg-white dark:bg-[#2b2c37] text-black dark:text-white font-bold shadow-md shadow-[#364e7e1a] max-w-md mx-auto  w-full px-8  py-8 rounded-xl">
						<h3 className="text-lg border-b-2 border-white pb-3">
							{isTenantEdit ? "Add " + tableName : "Edit " + tableName}
						</h3>

						{isTenantEdit ? <div className="w-full lg:w-12/12 px-4 mt-6 overflow-auto max-h-[65vh] ">
							{propertyNames?.map((obj, index) => {
								return <div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password"
									>
										{displayFormat(obj)}
									</label>
									<input
										type="text"
										name={"step_" + index}
										className="border-0 px-3 py-3 dark:text-indigo-900 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										onChange={(e) => handleSteps(e, obj)}
										value={steps.obj}
									/>
								</div>
							})} </div> : <div className="w-full lg:w-12/12 px-4 mt-6 overflow-auto max-h-[65vh]">
							{steps1 && propertyNames?.map((obj, index) => {
								return <div className="relative w-full mb-3">
									<label
										className="block uppercase text-xs font-bold mb-2"
										htmlFor="grid-password"
									>
										{displayFormat(obj)}
									</label>
									<input
										type="text"
										name={"step_" + index}
										className="border-0 px-3 dark:text-indigo-900 py-3 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										onChange={(e) => handleSteps1(e, obj)}
										value={steps1[obj]}
									/>
								</div>
							})} </div>
						}
						<div className="mt-8 flex flex-row justify-between border-t-2 border-white pt-3 mx-4 ">
							<button
								className="w-36 items-center dark:text-white dark:bg-blue-600   text-white bg-[#635fc7] py-1 px-1 rounded-xl"
								onClick={() => {
									handleClose()
								}}
							> Close</button>
							<button
								className="w-36 items-center dark:text-white dark:bg-blue-600  text-white bg-[#635fc7] py-1 px-1  rounded-xl"
								onClick={() => handleSave()}>
								{isTenantEdit ? "New" : "Update"} Record
							</button>
						</div>
					</div>
				</div >



			) : null
			}
		</>
	);
}

