import { crm } from "../redux/crm/actions";
import { menu } from "../redux/menu/actions";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { selectListItems } from '../redux/inspectionstatus/selectors';
import axios from 'axios';
import { crud } from "../redux/crud/actions";
import _ from 'lodash'
import { inspectionstatus } from "../redux/inspectionstatus/actions";
import { useUser } from '../contextHook/UserContext'
import { request } from '../requestTemp/index';
export default function AddEditProjectItem() {
    const dispatch = useDispatch();
    const { setMailModel, curretProjectId, curretProjectName } = useUser();
    // const propertyNames = Object.keys(columnName);
    const { activeBoardName, activeProName, mailModelOpen, activeEditId, isProjectEdit, projectItem } = useSelector(selectCurrentCRM);
    const { result } = useSelector(selectListItems)
    const { items } = result
    const [steps, setSteps] = useState({});
    const [steps1, setSteps1] = useState(projectItem);
    const [device, setDevice] = useState('mobile')

    const [apartmentList, setApartmentList] = useState([])
    const [apartmentType, setApartmentType] = useState('')
    const [apartmentNo, setApartmentNo] = useState('')
    const [apartmentNoList, setApartmentNoList] = useState([])
    const [apartmentBlock, setApartmentBlock] = useState([])
    const [apartmentBlockList, setApartmentBlockList] = useState([])
    const [apartmentId, setApartmentId] = useState("")

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [err, setErr] = useState("")



    const displayFormat = (data) => {
        return _.startCase(data)
    }

    const handleClose = () => {
        // dispatch(crm.trigger({ mailModelOpen: 'close' }))
        setMailModel('close')
    }

    // const handleSave = () => {
    //     if (!isProjectEdit) {
    //         dispatch(crud.update({ entity: tableName, id: activeEditId, jsonData: { entity: steps1 } })).then(() => {
    //             dispatch(menu.trigger({ projectModalOpen: 'close', projectReload: true }))
    //         })
    //     }
    //     else {
    //         dispatch(crud.create({ entity: tableName, jsonData: { entity: steps } })).then(() => {
    //             dispatch(menu.trigger({ projectModalOpen: 'close', projectReload: true }))
    //         })
    //     }
    // }

    const handleSteps1 = (e, key) => {
        setSteps1({ ...steps1, [key]: e.target.value })
    }

    const handleSteps = (e, key) => {
        setSteps({ ...steps, [key]: e.target.value })
    }
    const handleAparType = async (e) => {
        setApartmentBlock(e.target.value)
        const jsonData = {
            "distinctValueFor": "apartmentType",
            "searchByCond": {
                "projectName": curretProjectId,
                "apartmentBlock": e.target.value
            }
        }
        let data = await request.custom({ jsonData });
        if (data.success === true) {
            setApartmentList(data.result)
            // console.log(projects, "data")
        }
    }
    const handleApartNo = async (e) => {
        setApartmentType(e.target.value)
        const jsonData = {
            "distinctValueFor": "apartmentNo",
            "searchByCond": {
                "projectName": curretProjectId,
                "apartmentBlock": apartmentBlock,
                "apartmentType": e.target.value,

            }
        }
        let data = await request.custom({ jsonData });
        if (data.success === true) {
            setApartmentNoList(data.result)
            // console.log(projects, "data")
        }

    }
    const handleRemainData = (e) => {
        setApartmentNo(e.target.value)
        const response = axios.get(`https://api.greypath.co/api/v2/BAF_Apartment/findone?projectName=${curretProjectId}&apartmentType=${apartmentType}&apartmentBlock=${apartmentBlock}&apartmentNo=${e.target.value}`, {
            method: 'GET',
            mode: 'no-cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            referrerPolicy: 'no-referrer',
        }).then((data) => {
            if (data.data !== undefined && data.status === 200) {
                console.log(data.data, "remain")
                setApartmentId(data.data.result._id)

            }
        })

    }
    const handleMailPlot = () => {
        const response = axios.get(`https://api.greypath.co/api/v2/BAF_Apartment/findone?projectName=${curretProjectId}&plotNo=${apartmentBlock}`, {
            method: 'GET',
            mode: 'no-cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            referrerPolicy: 'no-referrer',
        }).then((data) => {
            if (data.data !== undefined && data.status === 200) {
                console.log(data.data, "remain")
                setApartmentId(data.data.result._id)
                if (apartmentBlock && name && email) {
                    setErr("")
                    const form_details = {
                        "fname": name,
                        "primaryEmail": email,
                        "apartmentId": data.data.result._id,
                        "plot": true
                    }
                    const response = axios.post('https://api.greypath.co/api/v2/BAF_Applicant/customerDetails', form_details, {
                        method: 'POST',
                        mode: 'no-cors',
                        cache: 'no-cache',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        referrerPolicy: 'no-referrer',
                    }).then((data) => {
                        if (data.data !== undefined && data.status === 200) {
                            setMailModel('close')
                            dispatch(crm.trigger({ mailModelOpen: 'close' }))
                        }
                    })

                }
                else {
                    setErr("*** All the fields are mandatory ***")
                }

            }
        })

    }
    const handleMail = () => {
        if (apartmentBlock && apartmentType && apartmentNo && name && email) {
            setErr("")
            const form_details = {
                "fname": name,
                "primaryEmail": email,
                "apartmentId": apartmentId,
                "plot": false
            }
            const response = axios.post('https://api.greypath.co/api/v2/BAF_Applicant/customerDetails', form_details, {
                method: 'POST',
                mode: 'no-cors',
                cache: 'no-cache',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                referrerPolicy: 'no-referrer',
            }).then((data) => {
                if (data.data !== undefined && data.status === 200) {
                    dispatch(crm.trigger({ mailModelOpen: 'close' }))
                    setMailModel('close')
                }
            })

        }
        else {
            setErr("*** All the fields are mandatory ***")
        }


    }

    useEffect(() => {
        dispatch(inspectionstatus.list({
            entity: '', jsonData: {
                "distinctValueFor": `${curretProjectName === "Plot Sale" ? "plotNo" : "apartmentBlock"}`,
                "searchByCond": {
                    "projectName": curretProjectId
                }
            }
        }))

    }, [])
    return (
        <>

            <div className="fixed right-0 top-0 px-2 py-4 overflow-scroll scrollbar-hide  z-50 left-0 bottom-0 justify-center items-center flex dropdown">
                <div
                    className="bg-indigo-100 dark:bg-[#2b2c37] text-black dark:text-white font-bold shadow-md shadow-[#364e7e1a] max-w-md mx-auto  w-full px-8  py-8 rounded-xl">
                    <h3 className="text-lg border-b-2 border-black pb-3">
                        Customer Request
                    </h3>

                    <p className="text-red-500 text-center mt-6">{err}</p>
                    <div className="w-full lg:w-12/12 px-4 mt-6 overflow-auto max-h-[65vh]">
                        <div className="relative w-full mb-3 ">
                            <label
                                className="block uppercase text-xs font-bold mb-2"
                                htmlFor="grid-password" >
                                Project Name
                            </label>
                            <input className=" block w-full px-3 py-2 " type="text" placeholder="Enter customer name" value={curretProjectName} disabled />
                            {/* <select
                                className="w-full p-2" value={activeProName}>
                            </select> */}
                        </div>
                        <div className="relative w-full mb-3 mt-6">
                            <label
                                className="block uppercase text-xs font-bold mb-2"
                                htmlFor="grid-password" >
                                {curretProjectName === "Plot Sale" ? "Plot No." : "Apartment Block"}
                            </label>
                            <select
                                className="w-full p-2" value={apartmentBlock} onChange={(e) => handleAparType(e)} >
                                {curretProjectName === "Plot Sale" ? <option className="text-gray-700" selected hidden>Select plot number</option> :
                                    <option className="text-gray-700" selected hidden>Select apartment Block</option>}

                                {curretProjectName === "Plot Sale" ?
                                    items.map((list, i) => (
                                        <option value={list.plotNo._id}>{list.plotNo.entityName}</option>
                                    ))

                                    : items.map((list, i) => (
                                        <option value={list.apartmentBlock._id}>{list.apartmentBlock.entityName}</option>
                                    ))}
                            </select>
                        </div>
                        {curretProjectName === "Plot Sale" ? "" : <>
                            <div className="relative w-full mb-3 mt-6">
                                <label
                                    className="block uppercase text-xs font-bold mb-2"
                                    htmlFor="grid-password" >
                                    Apartment Type
                                </label>
                                <select
                                    className="w-full p-2" onChange={(e) => handleApartNo(e)} value={apartmentType}>
                                    <option className="text-gray-700" selected hidden>Select apartment type</option>

                                    {apartmentList.map((list, i) => (
                                        <option value={list.apartmentType._id}>{list.apartmentType.entityName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="relative w-full mb-3 mt-6">
                                <label
                                    className="block uppercase text-xs font-bold mb-2"
                                    htmlFor="grid-password" >
                                    Apartment No.
                                </label>
                                <select
                                    className="w-full p-2" value={apartmentNo} onChange={(e) => handleRemainData(e)}>
                                    <option className="text-gray-700" selected hidden>Select apartment no.</option>

                                    {apartmentNoList.map((list, i) => (
                                        <option value={list.apartmentNo._id}>{list.apartmentNo.entityName}</option>
                                    ))}
                                </select>
                            </div>
                        </>

                        }

                        <div className="relative w-full mb-3 mt-6">
                            <label
                                className="block uppercase text-xs font-bold mb-2"
                                htmlFor="grid-password" >
                                customer name
                            </label>
                            <input className=" block w-full px-3 py-2 " type="text" placeholder="Enter customer name" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="relative w-full mb-3 mt-6">
                            <label
                                className="block uppercase text-xs font-bold mb-2"
                                htmlFor="grid-password" >
                                customer mail
                            </label>
                            <input className=" block w-full px-3 py-2 " type="email" placeholder="Enter customer mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>






                    </div>

                    <div className="mt-8 flex flex-row justify-between border-t-2 border-black pt-3 mx-4 ">
                        <button
                            className="px-6 py-2 items-center dark:text-white dark:bg-blue-600   text-white bg-[#635fc7] py-1 px-1 rounded-xl"
                            onClick={handleClose}
                        > Close</button>
                        <button
                            className="px-6 py-2 items-center dark:text-white dark:bg-blue-600  text-white bg-[#635fc7] py-1 px-1  rounded-xl"
                            onClick={curretProjectName === "Plot Sale" ? handleMailPlot : handleMail}
                        >
                            Send Request
                        </button>
                    </div>
                </div>
            </div >

        </>
    );
}

