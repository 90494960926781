import { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [currentProjects, setCurrentProjects] = useState({});
  const [curretProjectId, setCurrentProjectId] = useState("");
  const [curretProjectName, setCurrentProjectName] = useState("");
  const [custumerDetails, setCustumerDetails] = useState([]);
  const [custumerModel, setCustumerModel] = useState("close");
  const [mailModel, setMailModel] = useState("close");

  return (
    <UserContext.Provider value={{ projects, setProjects, curretProjectId, setCurrentProjectId, curretProjectName, setCurrentProjectName, custumerDetails, setCustumerDetails, custumerModel, setCustumerModel, currentProjects, setCurrentProjects, mailModel, setMailModel }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);