import { createSelector } from 'reselect';

const selectProjectCards = (state) => state.projectcards;

export const selectCurrentItem = createSelector([selectProjectCards], (projectcards) => projectcards.current);

export const selectListItems = createSelector([selectProjectCards], (projectcards) => projectcards.list);

export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectProjectCards], (projectcards) => projectcards.create);

export const selectUpdatedItem = createSelector([selectProjectCards], (projectcards) => projectcards.update);

export const selectReadItem = createSelector([selectProjectCards], (projectcards) => projectcards.read);

export const selectDeletedItem = createSelector([selectProjectCards], (projectcards) => projectcards.delete);

export const selectSearchedItems = createSelector([selectProjectCards], (projectcards) => projectcards.search);

export const selectItemsCount = createSelector([selectProjectCards], (projectcards) => projectcards.count);

export const selectItemsFilter = createSelector([selectProjectCards], (projectcards) => projectcards.filter);