// import history from '@/utils/history';
import codeMessage from './codeMessage';
import { toast, snackbar } from 'tailwind-toast'


const errorHandler = (error) => {
	const data = {
		success: false,
	};

	toast().danger("",error).with({
		shape: 'pill',
		duration: 2000,
		speed: 1000,
		positionX: 'end',
		icon: 'fa fa-check-circle',
		positionY: 'top',
		color: 'bg-white',
		fontColor: 'white',
		fontTone: 'bg-lightBlue-900'
	}).show()
	

};

export default errorHandler;
