import { menu } from "./../redux/menu/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrent } from './../redux/menu/selectors';
// import { crud } from '@/redux/crud/actions';

export default function Confirmation() {
	const dispatch = useDispatch();
	const { confirmation } = useSelector(selectCurrent);

	const handleCancel = () => {
		dispatch(menu.trigger({ confirmation: false, isDelete: false }))
	}

	const handleDelete = () => {
		dispatch(menu.trigger({ confirmation: false, isDelete: true }))
	}
	return (
		<>
			{confirmation == true ? (
				<>
					<div className="absolute fixed w-full inset-0 z-50 outline-none focus:outline-none flex flex-warp justify-center align-middle">
						<div className="relative w-auto my-6 mx-auto max-w-sm">
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								<div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
									<h3 className="text-xl font-semibold">
										Confirmation Box
									</h3>
									<button
										className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => handleCancel()}
									>
										<span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
											×
										</span>
									</button>
								</div>
								<div className="relative p-2 flex flex-wrap justify-center align-middle" >
									<div className="w-full lg:w-9/12 h-16 mt-3">
										<div className="uppercase text-blueGray-600 text-xs font-bold">
											Are you sure you want to delete this item ?
										</div>
									</div>
								</div>
								<div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
									<button
										className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
										type="button"
										onClick={() => handleCancel()}
									>
										Cancel
									</button>
									<button
										className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
										type="button"
										onClick={() => handleDelete()}
									>
										Delete
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}