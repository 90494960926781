import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inspectioncards } from "../../redux/inspectioncards/actions";
import { crm } from "../../redux/crm/actions";
import { selectCurrentCRM } from "./../../redux/crm/selectors"
import AddEditInspectionItem from "../../modals/AddEditInspectionItem"
import { selectListItems } from '../../redux/inspectioncards/selectors';
import { useNavigate } from 'react-router-dom';
import Confirmation from "../../components/Modal/Confirmation/index.js";
import _ from 'lodash'
import Pagination from "../../components/Pagination";
import TableDropdown from "../../modals/TableDropdown.js";
import useDarkMode from "../../hooks/useDarkMode.js";
import showSidebarIcon from "../../assets/img/eye.png";
import ProjectView from "../../modals/ProjectView"
import MailModel from "../../modals/MailModel"
import { useUser } from '../../contextHook/UserContext'
import { request } from '../../requestTemp/index';
export default function InspectionItems() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const { activeBoardName, activeProName, inspectionReload, inspectionModalOpen } = useSelector(selectCurrentCRM)
	const { result } = useSelector(selectListItems);
	const { userModelOpen, mailModelOpen } = useSelector(selectCurrentCRM)
	const { items } = result;
	const [colorTheme, setTheme] = useDarkMode();
	// const { current, total, pageSize } = pagination;
	// const pageCount = pageSize;
	const [param, setParam] = useState('')
	const { curretProjectId, curretProjectName, custumerDetails, setCustumerDetails, projects, setCurrentProjects,
		setCustumerModel, custumerModel, setMailModel, mailModel } = useUser();


	const handleSearch = (emit) => {
	}

	const handleKandbanBoard = () => {
		navigate('/inspection-crm/inspection-boards')
		dispatch(crm.trigger({ inspectionReload: true }))
	}

	const displayFormat = (data) => {
		return _.startCase(data)
	}
	const handleProjectView = (project) => {
		console.log(project, "project")
		// dispatch(crm.trigger({ userModelOpen: 'open', projectItem: project }))
		setCurrentProjects(project)
		setCustumerModel("open")
	}
	const handleMailrequest = () => {
		// dispatch(crm.trigger({ mailModelOpen: 'open' }))
		setMailModel("open")

	}

	const handleProject = () => {
		navigate('/inspection-crm/project-boards')
	}

	// useEffect(() => {
	// 	console.log(user,"user")
	// 	if (inspectionReload) {
	// 		dispatch(inspectioncards.list({
	// 			entity: 'BAF_Application', jsonData: {
	// 				"limit": 10,
	// 				"page": 1,
	// 				"offset": 1,
	// 				"sortBy": "-createdAt",
	// 				"params": {
	// 					"isDeleted": false,
	// 					"projectId": activeBoardName
	// 				},
	// 				"isAdminListing": true
	// 			}
	// 		}))
	// 		dispatch(crm.trigger({ inspectionReload: false }))
	// 	}
	// }, [inspectionReload])
	const custemerDetails = async () => {
		const entity = 'BAF_Application'
		const jsonData = {
			"limit": 10,
			"page": 1,
			"offset": 1,
			"sortBy": "-createdAt",
			"params": {
				"isDeleted": false,
				"projectId": curretProjectId !== '' ? curretProjectId : param,
			},
			"isAdminListing": true
		}
		let data = await request.filter({ entity, jsonData });
		if (data.success === true) {
			setCustumerDetails(data.result)
			// console.log(projects, "data")
		}

	}

	useEffect(() => {
		const queryString = window.location.search;
		const queryParams = new URLSearchParams(queryString);
		const param1 = queryParams.get('proId');
		setParam(param1)
		custemerDetails()

		// dispatch(inspectioncards.list({
		// 	entity: 'BAF_Application', jsonData: {
		// 		"limit": 10,
		// 		"page": 1,
		// 		"offset": 1,
		// 		"sortBy": "-createdAt",
		// 		"params": {
		// 			"isDeleted": false,
		// 			"projectId": curretProjectId !== '' ? curretProjectId : param1,
		// 		},
		// 		"isAdminListing": true
		// 	}
		// }))
	}, [param])

	return (
		<>
			<div className="flex flex-col item-center">
				<header className="flex justify-between h-20 w-full mx-auto px-5 dark:text-white items-center">
					<div className="flex justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<h3 className="text-xl font-bold" onClick={() => handleProject()}>Projects</h3>
									<span
										className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
										<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#" className="opacity-60 text-md font-bold">
										<span>
											{curretProjectName}
										</span>
									</a>
									<span
										className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
										<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#" className="opacity-60 text-md font-bold">
										Customer Details
									</a>
									<span
										className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
									</span>
								</li>
							</ol>
						</nav>
					</div>
					<div className="flex justify-end w-full" >
						<a href={curretProjectName === "Plot Sale" ? `https://www.greypathsolutions.com/development/plot` : `https://www.greypathsolutions.com/development/apartment?projectname=${curretProjectName}&projectid=${param}`} target="_blank">
							<button className="w-auto mx-3 button ring-1 ring-insert ring-blue-600 bg-transparent text-blue-600 px-3 rounded p-2 text-xs font-bold"
							// onClick={() => {
							// 	handleKandbanBoard()
							// }}
							> + Add Customer
							</button>

						</a>

						<button className="w-auto mx-3 button rounded dark:bg-blue-600 p-2 text-xs font-bold"
							onClick={() => {
								handleMailrequest()
							}} > Send Request to Customer
						</button>
					</div>
				</header >
				<div>
					<Confirmation />
					<div className="flex flex-col  px-3 mx-5 h-[600px] shadow-lg rounded bg-white dark:bg-[#2b2c37]">
						{/* <div className="flex flex-row items-center justify-start w-full h-14 my-2   ">
							<form className="mx-3">
								<div className="relative flex w-full flex-wrap items-stretch">
									<span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
										<i className="fas fa-search"></i>
									</span>
									<input
										type="text"
										placeholder="Search here..."
										value={search}
										onChange={(e) => handleSearch(e)}
										className="border-x border-y px-3 py-2 border-black border-opacity-40 placeholder-blueGray-300 text-grey-600 relative rounded text-sm shadow outline-none focus:outline-none focus:ring w-[300px] pl-10"
									/>
								</div>
							</form>
							<div className="flex justify-end w-36 mx-3 space-x-3" >
								<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full"
										onChange={(e) => console.log('ok')} >
										<option value="1" key={1}>Stage</option>
									</select>
								</div>
							</div>
							<div className="flex justify-end w-36  mx-3 space-x-3" >
								<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full"
										onChange={(e) => console.log('ok')} >
										<option value="1" key={1}>Status</option>
									</select>
								</div>
							</div>
							<div className="flex justify-end w-36 space-x-3" >
								<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full"
										onChange={(e) => console.log('ok')} >
										<option value="1" key={1}>Filter</option>
									</select>
								</div>
							</div>
							<div className="flex justify-end w-36 mx-auto space-x-3 mr-0" >
								<button className="w-auto px-3 button bg-transparent rounded ring-1 ring-inset ring-blue-600 text-blue-600 p-2 text-xs font-bold"
								> + Assign
								</button>
							</div>
							<div className="w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span className="px-4 py-2 w-20 text-center text-sm font-semibold rounded-l-md bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">List</span>
									<span
										onClick={() => {
											handleKandbanBoard()
										}}
										className="px-4 py-2 w-20 text-center  text-sm  font-semibold rounded-r-md bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Kanban</span>
								</label>
							</div>
						</div> */}
						<div className="min-h-[450px] w-full overflow-auto  mt-0">
							<table className="items-center border-collapse w-full  ">
								<thead>
									<tr key={'table'}>
										<th key={0}
											className="flex flex-col w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
											<input type="checkbox" />
										</th>
										<th key={1}
											className="w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
											{displayFormat('customerName')}
										</th>
										<th key={2}
											className="w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
											{curretProjectName === "Plot Sale" ? displayFormat(''): displayFormat('apartmentType')}
										</th>
										<th key={3}
											className="w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
											{displayFormat('contactNo.')}
										</th>
										<th key={3}
											className="w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
											{displayFormat('Address')}
										</th>
										{/* <th key={4}
											className="w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
											{displayFormat('Stage')}
										</th> */}
										<th key={5}
											className="w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
											{displayFormat('status')}
										</th>
										<th key={6}
											className="w-auto mx-2 px-3  dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ">
											View
										</th>
									</tr>
								</thead>

								<tbody>
									{custumerDetails.map((item, idx) => {
										return <tr key={idx}  >
											<td key={0}>
												<span className="flex flex-col w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
													<input type="checkbox" />
												</span>
											</td>
											<td key={1}>
												<span className="flex flex-col w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
													{/* <div>{item["Customer Contacts"]} </div> */}
													<div>{item.primaryApplicantId.fname}</div>
												</span>
											</td>
											<td key={2}>
												<span className="flex flex-col  w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
													<div>{item.apartmentId.apartmentType?item.apartmentId.apartmentType.entityName:""}</div>
													{/* <div>{item["blockTower"]}</div>
													<div>{item["TypeOfHouse"]}</div> */}
												</span>
											</td>
											<td key={3}>
												<span className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
													{item.primaryApplicantId.telephoneNumber ? item.primaryApplicantId.telephoneNumber: item.primaryApplicantId.mobileNumber}
												</span>
											</td>
											<td key={4}>
												<span className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
													{item.primaryApplicantId.permanentAddress ? item.primaryApplicantId.permanentAddress.city : ''},&nbsp;	{item.primaryApplicantId.permanentAddress ? item.primaryApplicantId.permanentAddress.state : ''}
												</span>
											</td>
											{/* <td key={5}>
												<span className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
													Stage
												</span>
											</td> */}

											<td key={6}>
												{item.status == 'Draft' ?
													<button className="inline-flex items-center mx-2 rounded-md bg-transparent px-2 py-1 text-xs font-medium text-yellow-500 ring-1 ring-inset ring-yellow-600" >
														{item.status}
													</button> :
													item.status == 'Submitted' ? <button className="inline-flex items-center mx-2 rounded-md bg-transparent px-2 py-1 text-xs font-medium text-purple-500 ring-1 ring-inset ring-purple-600" >
														{item.status}
													</button>
														:
														item.status == 'Under Review' ? <button className="inline-flex items-center mx-2 rounded-md bg-transparent px-2 py-1 text-xs font-medium text-brown-500 ring-1 ring-inset ring-brown-600" >
															{item.status}
														</button>
															:
															item.status == 'Approved' ? <button className="inline-flex items-center mx-2 rounded-md bg-transparent px-2 py-1 text-xs font-medium text-green-500 ring-1 ring-inset ring-green-600" >
																{item.status}
															</button> : <button className="inline-flex items-center mx-2 rounded-md bg-transparent px-2 py-1 text-xs font-medium text-red-500 ring-1 ring-inset ring-red-600" >
																{item.status}
															</button>
												}
											</td>
											<td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
												{/* <TableDropdown activeId={item._id} item={item} tableType={'inspection'} /> */}
												<img src={showSidebarIcon} alt="showSidebarIcon" className="w-5 h-5 cursor-pointer" onClick={() => handleProjectView(item)} />
											</td>
										</tr>

									})}
								</tbody>
							</table>
						</div >
						<div >
							{custumerModel == 'open' ? <ProjectView /> : ""}
							{mailModel == 'open' ? <MailModel /> : ""}
							{/* <Pagination
								pageCount={pageCount}
								pageIndex={current - 1}
							/> */}
						</div>
					</div>
				</div>
			</div>

		</>

	);
}
