import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { crm } from "../../redux/crm/actions";
import { selectCurrentCRM } from "../../redux/crm/selectors"
import AddEditInspectionItem from "../../modals/AddEditInspectionItem"
import { selectListItems } from '../../redux/inspectionchecklist/selectors.js';
import { useNavigate } from 'react-router-dom';
import Confirmation from "../../components/Modal/Confirmation/index.js";
import _ from 'lodash'
import Pagination from "../../components/Pagination";
import { Switch } from "@headlessui/react";
import {
	Accordion,
	AccordionHeader,
	AccordionBody,
} from "@material-tailwind/react";
import { inspectionchecklist } from "../../redux/inspectionchecklist/actions.js";


export default function ProjectChecklist() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [open, setOpen] = React.useState(1);
	const handleOpen = (value) => setOpen(open === value ? 0 : value);

	const { activeBoardName, inspectionReload, inspectionModalOpen, activeCustomer, checklistProjectType } = useSelector(selectCurrentCRM)
	const { result } = useSelector(selectListItems);
	const { items, pagination } = result;
	const [pass, setPass] = useState([]);
	const [fail, setFail] = useState([]);
	const [categoryName, setCategoryName] = useState([]);
	const [notDone, setNotDone] = useState([]);
	const [checkList, setCheckList] = useState([]);
	const { current, total, pageSize } = pagination;
	const pageCount = pageSize;

	const toggleStatus = (option, idx) => {
		if (option == 'passed') {
			setPass({ ...pass, [idx]: !pass[idx] })
			setFail({ ...fail, [idx]: pass[idx] })
			setNotDone({ ...notDone, [idx]: pass[idx] })
		}
		else if (option == 'failed') {
			setFail({ ...fail, [idx]: !fail[idx] })
			setPass({ ...pass, [idx]: fail[idx] })
			setNotDone({ ...notDone, [idx]: fail[idx] })
		}
		else if (option == 'notDone') {
			setNotDone({ ...notDone, [idx]: !notDone[idx] })
			setFail({ ...fail, [idx]: notDone[idx] })
			setPass({ ...pass, [idx]: notDone[idx] })
		}
	}

	const [search, setSearch] = useState('')
	const handleSearch = (emit) => {
	}
	const handleCreateIsse = (item) => {
		dispatch(crm.trigger({ projectChecklistOpen: 'close', issueModalOpen: 'open', isIssueEdit: false, issueItem: item }))
	}

	useEffect(() => {
		dispatch(inspectionchecklist.list({ entity: 'PDI_Checklist_Items', options: {} }))
	}, [])

	useEffect(() => {
		let _categoryName = []
		let checkList = []
		let _pass = []
		let _fail = []
		let _notDone = []

		items?.map((item, index) => {
			_categoryName.push(item.categoryName.Name)
			_pass[index] = false
			_fail[index] = false
			_notDone[index] = false
		})
		let uniqueCategoryList = [...new Set(_categoryName)]
		uniqueCategoryList?.map((obj) => {
			checkList.push(items.filter((item) => item.categoryName.Name == obj)) //&& item.ProjectType.Name == checklistProjectType
		})
		setCheckList(checkList)
		setPass(_pass)
		setFail(_fail)
		setNotDone(_notDone)
		setCategoryName(uniqueCategoryList)
	}, [items])

	const handleProject = () => {
		navigate('/inspection-crm/project-boards')
	}

	const handleKandbanBoard = () => {
		navigate('/inspection-crm/inspection-boards')
		dispatch(crm.trigger({ inspectionReload: true }))
	}

	const displayFormat = (data) => {
		return _.startCase(data)
	}

	return (
		<>
			<div className="flex flex-col item-center">
				<header className="flex justify-between h-20 w-full mx-auto px-5 dark:text-white items-center">
					<div className="flex justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<h3 className="text-xl font-bold" onClick={() => handleProject()}>Projects</h3>
									<span
										className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
										<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#" className="opacity-60 text-md font-bold">
										<span>
											{activeBoardName}
										</span>
									</a>
									<span
										className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
										<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#" className="opacity-60 text-md font-bold">
										{activeCustomer}
									</a>
									<span
										className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
										<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#" className="opacity-60 text-md font-bold">
										Checklist
									</a>
								</li>
							</ol>
						</nav>
					</div>
					<div className="flex justify-end w-full " >

						<button className="w-36 w-min-36 button uppercase hidden md:block rounded-l-3xl dark:bg-blue-600 p-2 text-xs font-bold"
							onClick={() => {
								handleKandbanBoard()
							}} > + Add Task
						</button>
					</div>
				</header >
				<>
					<Confirmation />
					<div className="flex flex-col  px-3 mx-5 h-[600px] shadow-lg rounded bg-white dark:bg-[#2b2c37]">
						<div className="flex flex-row items-center justify-start w-full h-14 my-2   ">
							<form className="mx-3">
								<div className="relative flex w-full flex-wrap items-stretch">
									<span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
										<i className="fas fa-search"></i>
									</span>
									<input
										type="text"
										placeholder="Search here..."
										value={search}
										onChange={(e) => handleSearch(e)}
										className="border-x border-y px-3 py-2 border-black border-opacity-40 placeholder-blueGray-300 text-grey-600 relative rounded text-sm shadow outline-none focus:outline-none focus:ring w-[300px] pl-10"
									/>
								</div>
							</form>
							<div className="flex justify-end w-36 mx-3 space-x-3" >
								<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full"
										onChange={(e) => console.log('ok')} >
										<option value="1" key={1}>All Task</option>
									</select>
								</div>
							</div>
							<div className="flex justify-end w-36  mx-3 space-x-3" >
								<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full"
										onChange={(e) => console.log('ok')} >
										<option value="1" key={1}>Status</option>
									</select>
								</div>
							</div>
							<div className="flex justify-end w-36 space-x-3" >
								<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2 dark:text-indigo-900 py-2 placeholder-blue-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full"
										onChange={(e) => console.log('ok')} >
										<option value="1" key={1}>filter</option>
									</select>
								</div>
							</div>
						</div>
						<div className="flex flex-row item-center w-full justify-around  text-sm font-bold  h-10 my-2 py-2 bg-indigo-100 bg-opacity-20 border-x border-y border-black border-opacity-40">
							<div className="w-[750px]">
								Task Name
							</div>
							<div className="w-[200px]">
								Status
							</div>
							<div className="w-[100px]">
								Remarks
							</div>
							<div className="w-[100px]">
								Action
							</div>
						</div>
						{categoryName?.map((item, index) => {
							return <>

								<Accordion
									className="m-1"
									open={open == (index + 1)}>
									{checkList[index].length > 0 ? <AccordionHeader onClick={() => handleOpen(index + 1)} className="border-b border-b-indigo-200 h-10">
										<div className="flex flex-row items-center justify-between px-10 dark:bg-blue-600" >
											<label className="text-sm dark:text-white text-black font-bold">
												{item}
											</label>
											<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
											<div className="text-xs text-black font-bold text-opacity-50	">&#40;{checkList[index].length + 1} Items &#41;</div>
										</div>
									</AccordionHeader> : ""}
									<AccordionBody className="my-2 py-0 mt-4">
										{checkList[index].map((obj, idx) => {
											return <div className="flex flex-row w-full item-center justify-around px-10 border-x border-y w-[1300px] mx-10 m-1 border-black border-opacity-30 items-baseline  texrt-slate-200 text-sm dark:bg-blue-900/5">
												<div className="mr-auto text-xs font-bold text-black font-bold text-opacity-50	 w-[600px]">{obj.description}</div>
												<div
													onClick={() => toggleStatus('notDone', idx)}
													className={notDone[idx] ? "inline-flex items-center rounded-md bg-blue-500 mx-2 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-blue-700/10" : "inline-flex items-center rounded-md bg-blue-50 mx-2 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 bg-opacity-40"}>
													<svg width="14px" height="14px" viewBox="0 0 24 24" fill="none" >
														<path d="M12 22C7.17 22 3.25 18.08 3.25 13.25C3.25 8.42 7.17 4.5 12 4.5C16.83 4.5 20.75 8.42 20.75 13.25" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
														<path opacity="0.4" d="M12 8V13" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
														<path opacity="0.4" d="M9 2H15" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
														<path opacity="0.4" d="M19 17V21" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
														<path opacity="0.4" d="M16 17V21" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													</svg>
													<div className="mx-2">Not Done</div>
												</div>
												<div
													onClick={() => toggleStatus('failed', idx)}
													className={fail[idx] ? "inline-flex items-center mx-2 rounded-md bg-red-500 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-red-600/10 " : "inline-flex items-center mx-2 rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 bg-opacity-40"}>
													<svg fill="#000000" height="14px" width="14px" version="1.1" id="Capa_1"
														viewBox="0 0 490 490">
														<polygon points="11.387,490 245,255.832 478.613,490 489.439,479.174 255.809,244.996 489.439,10.811 478.613,0 245,234.161 
	11.387,0 0.561,10.811 234.191,244.996 0.561,479.174 "/>
													</svg>
													<div className="mx-2">Failed</div>
												</div>
												<div
													onClick={() => toggleStatus('passed', idx)}
													className={pass[idx] ? "inline-flex items-center rounded-md bg-green-500 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-green-600/20 " : "inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 bg-opacity-40"}>
													<svg fill="#000000" height="14px" width="14px" version="1.1" id="Capa_1"
														viewBox="0 0 490 490">
														<polygon points="452.253,28.326 197.831,394.674 29.044,256.875 0,292.469 207.253,461.674 490,54.528 " />
													</svg>
													<div className="mx-2">Passed</div>
												</div>
												<div className="flex w-[100px] h-8 mx-3 items-center text-xs bg-white  cursor-pointer    dark:bg-transparent flex justify-center items-center rounded">
													{!fail[idx] ?
														<div className="w-36 w-min-36 button   p-1 bg-white text-xs text-black font-bold text-opacity-50 "
														> {idx == 3 || idx == 1 || idx == 4 ? "View Remarks" : ""} </div>
														: '-'}
												</div>
												<div className="flex w-[100px] h-8 mx-3 items-center text-xs bg-white  cursor-pointer    dark:bg-transparent flex justify-center items-center rounded">
													{!fail[idx] ?
														<button
															onClick={() => handleCreateIsse(obj)}
															className="w-36 w-min-36 button   p-1 bg-white text-xs text-yellow-900 font-bold border-y border-x border-yellow-900"
														>
															{idx == 3 || idx == 1 || idx == 4 ? <div className="flex flex-row item-basline justify-around">
																<svg fill="#000000" version="1.1" id="Layer_1"
																	width="14px" height="14px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" >
																	<path d="M256-2C114.609-2,0,112.609,0,254s114.609,256,256,256s256-114.609,256-256S397.391-2,256-2z M256,470
	c-119.297,0-216-96.703-216-216S136.703,38,256,38s216,96.703,216,216S375.297,470,256,470z"/>
																	<path d="M349.328,142H162.664C152.359,142,144,150.359,144,160.656v122.672c0,10.312,8.359,18.672,18.664,18.672H176v64l37.336-64
	h135.992c10.312,0,18.672-8.359,18.672-18.672V160.656C368,150.359,359.641,142,349.328,142z M336,270h-96v-16h96V270z M336,222H176
	v-16h160V222z M336,190H176v-16h160V190z"/>
																</svg>
																<div className="ml-1">
																	View Issue
																</div></div> : " + Add Issue"}
														</button> : '-'}
												</div>
											</div>
										})}
									</AccordionBody>
								</Accordion ></>
						})}

						{/* <div className="min-h-[450px] w-full overflow-auto  ">
							<table className="items-center border-collapse w-full  ">
								<thead>
									<tr key={'table'}>
										{propertyNames.map((column, index) => {
											return <th key={index}
												className="w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
												{displayFormat(column)}
											</th>
										})}
										<th key={propertyNames.length + 1}
											className="w-auto mx-2 px-3  dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ">
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									{items.map((item, idx) => {
										return <tr key={idx}  >
											{propertyNames.map((col, index) => {
												return <td key={index}>
													<span className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" >
														{typeof (item[col]) != 'object' ? item[col] : item[col]._id
														}
													</span>
												</td>
											})
											}
											<td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
												<TableDropdown activeId={item._id} item={item} tableType={'inspection'} />
											</td>
										</tr>
									})}
								</tbody>
							</table>
						</div > */}
						{/* <div >
							<Pagination
								pageCount={pageCount}
								pageIndex={current - 1}
							/>
						</div> */}
					</div >
				</>
				{/* <CardTable tableName="PDI_Inspection_Items" columnName={columnName} /> */}
			</div >
			{/* {inspectionModalOpen === 'open' ? <AddEditInspectionItem items={items} tableName="PDI_Inspection_Items" columnName={columnName} /> : "" */}
			{/* } */}
		</>

	);
}
