import { createSelector } from 'reselect';

const selectInspectionChecklist = (state) => state.inspectionchecklist;

export const selectCurrentItem = createSelector([selectInspectionChecklist], (inspectionchecklist) => inspectionchecklist.current);

export const selectListItems = createSelector([selectInspectionChecklist], (inspectionchecklist) => inspectionchecklist.list);

export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectInspectionChecklist], (inspectionchecklist) => inspectionchecklist.create);

export const selectUpdatedItem = createSelector([selectInspectionChecklist], (inspectionchecklist) => inspectionchecklist.update);

export const selectReadItem = createSelector([selectInspectionChecklist], (inspectionchecklist) => inspectionchecklist.read);

export const selectDeletedItem = createSelector([selectInspectionChecklist], (inspectionchecklist) => inspectionchecklist.delete);

export const selectSearchedItems = createSelector([selectInspectionChecklist], (inspectionchecklist) => inspectionchecklist.search);

export const selectItemsCount = createSelector([selectInspectionChecklist], (inspectionchecklist) => inspectionchecklist.count);

export const selectItemsFilter = createSelector([selectInspectionChecklist], (inspectionchecklist) => inspectionchecklist.filter);