import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import App from './app';
// import { AppContextProvider } from './app/appContext';
// import history from './utils/history';
import { BrowserRouter } from "react-router-dom";
import store from './redux/store'
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
	<BrowserRouter>
		<Provider store={store}>
			{/* <AppContextProvider> */}
			<App />
			{/* </AppContextProvider> */}
		</Provider>
	</BrowserRouter>
	// </React.StrictMode>
);