import * as actionTypes from './types';


const INITIAL_KEY_STATE = {
	value: null
};

const INITIAL_STATE = {
	value: {
		isEdit: null,
		search: '',
		isDelete: false,
		confirmation: false,
		tenantReload: false,
		masterReload: false,
		projectReload: true,
		modalConfirmation: false,
		isDeleteModal: false,
		entity: "Entity",
		activeMetadataId: '',
		activeCrudId: '',
		addTenant: 'close',
		tableName: '',
		columnName: {},
		pages: 1,
		page: 1,
		count: 10,
		activeMenu: 3,
		crudData: {}

	},
	trigger: INITIAL_KEY_STATE,
};


const menuReducer = (state = INITIAL_STATE, action) => {
	const { payload } = action;
	switch (action.type) {
		case actionTypes.MENU_ON_CHANGE:
			return {
				value: {
					...state.value,
					...payload
				}
			};
		default:
			return state;
	}
};

export default menuReducer;
