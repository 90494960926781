import * as actionTypes from './types';


const INITIAL_KEY_STATE = {
	value: null
};

const INITIAL_STATE = {
	value: {
		activeBoardName: '-',
		activeProName: '-',
		activeCustomer: '',
		boardType: 'drag',
		projectModalOpen: 'close',
		isProjectEdit: false,
		activeEditId: '',
		inspectionReload: false,
		inspectionModalOpen: 'close',
		isInspectionEdit: false,
		inspectionItem: {},
		checklistReload: false,
		checklistModalOpen: 'close',
		isCheckllistEdit: false,
		activeChecklistId: '',
		checklistItem: {},
		issueReload: false,
		issueModalOpen: 'close',
		isIssueEdit: false,
		activeIssueId: '',
		issueItem: {},
		projectChecklistOpen: 'close',
		projectItem: {},
		userModelOpen: 'close',
		mailModelOpen: 'close'
	},
	trigger: INITIAL_KEY_STATE,
};


const crmReducer = (state = INITIAL_STATE, action) => {
	const { payload } = action;
	switch (action.type) {
		case actionTypes.CRM_ON_CHANGE:
			return {
				value: {
					...state.value,
					...payload
				}
			};
		default:
			return state;
	}
};

export default crmReducer;
