import axios from 'axios';
// import { API_APP_BASE_URL } from '@/config/serverApiConfig';

import errorHandler from './errorHandler';
import successHandler from './successHandler';
import storePersist from './../redux/storePersist';

// axios.defaults.baseURL = API_APP_BASE_URL;


const request = {
	create: async ({ entity, jsonData }) => {
		console.log('🚀 Create Request 🚀 ~ file: request.js ~ line 19 ~ create: ~ jsonData', jsonData);
		const token = storePersist.get('auth')?.current
		try {
			const response = await axios.post('https://api.greypath.co/api/v1/' + entity + '/create', jsonData, {
				method: 'POST',
				mode: 'no-cors',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token
				},
				redirect: 'follow',
				referrerPolicy: 'no-referrer',
			})
			successHandler(response, {
				notifyOnSuccess: true,
				notifyOnFailed: true,
			});
			return response.data;
		} catch (error) {
			return errorHandler(error);
		}
	},
	importexcel: async ({ entity, jsonData }) => {
		console.log('🚀 Create Request 🚀 ~ file: request.js ~ line 19 ~ create: ~ jsonData', jsonData);
		const token = storePersist.get('auth')?.current
		try {
			const response = await axios.post('https://api.greypath.co/api/v1/' + entity + '/importexcel', jsonData, {
				method: 'POST',
				mode: 'no-cors',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token
				},
				redirect: 'follow',
				referrerPolicy: 'no-referrer',
			})
			successHandler(response, {
				notifyOnSuccess: true,
				notifyOnFailed: true,
			});
			return response.data;
		} catch (error) {
			return errorHandler(error);
		}
	},
	read: async ({ entity, id }) => {
		const token = storePersist.get('auth').current
		try {
			const response = await axios.get('https://api.greypath.co/api/v1/' + entity + '/read?id=' + id, {
				method: 'GET',
				mode: 'no-cors',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Authorization': 'Bearer ' + token
				},
				redirect: 'follow',
				referrerPolicy: 'no-referrer',
			});
			successHandler(response, {
				notifyOnSuccess: false,
				notifyOnFailed: true,
			});
			return response.data;
		} catch (error) {
			return errorHandler(error);
		}
	},
	update: async ({ entity, id, jsonData }) => {
		console.log('🚀 ~ file: request.js ~ line 34 ~ update: ~ id', id);
		console.log('🚀 Update Request 🚀 ~ file: request.js ~ line 42 ~ update: ~ jsonData', jsonData);
		const token = storePersist.get('auth').current
		try {
			const response = await axios.put('https://api.greypath.co/api/v1/' + entity + '/update?id=' + id, jsonData, {
				method: 'PUT',
				mode: 'no-cors',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token
				},
				redirect: 'follow',
				referrerPolicy: 'no-referrer',
			});
			successHandler(response, {
				notifyOnSuccess: true,
				notifyOnFailed: true,
			});
			return response.data;
		} catch (error) {
			return errorHandler(error);
		}
	},
	delete: async ({ entity, id, options = {} }) => {
		const token = storePersist.get('auth').current
		try {
			const response = await axios.delete('https://api.greypath.co/api/v1/' + entity + '/delete?id=' + id, {
				method: 'DELETE',
				mode: 'no-cors',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
				// redirect: 'follow',
				referrerPolicy: 'no-referrer',
			});
			successHandler(response, {
				notifyOnSuccess: true,
				notifyOnFailed: true,
			});
			return response.data;
		} catch (error) {
			return errorHandler(error);
		}
	},
	list: async ({ entity, options}) => {
		const token = storePersist.get('auth').current
		try {
			let query = '?';
			for (var key in options) {
				query += key + '=' + options[key] + '&';
			}
			query = query.slice(0, -1);
			const response = await axios.get('https://api.greypath.co/api/v1/' + entity + '/list' + query, {
				method: 'GET',
				mode: 'no-cors',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
				// redirect: 'follow',
				referrerPolicy: 'no-referrer',
			});

			successHandler(response, {
				notifyOnSuccess: false,
				notifyOnFailed: true,
			});
			return response.data;
		} catch (error) {
			return errorHandler(error);
		}
	},
	
};


export default request;
